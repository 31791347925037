import React from "react";
import { Link } from "react-router-dom";
import { TutorialGroup } from "../../../graphql/queries/tutorials.js";
import ImageItem, { SrcSetSizes } from "../../gallery/_common/ImageItem.js";
import {
    card,
    imageWrapper,
    cardTitle,
    tutorialItem,
    tutorialItemLink,
    tutorialList,
} from "./TutorialGroupCard.css.js";

interface Props {
    tutorialGroup: TutorialGroup;
    srcSetSizes: SrcSetSizes;
}
const TutorialGroupCard: React.FC<Props> = ({ tutorialGroup, srcSetSizes }) => {
    const {
        image,
        tutorials,
        content: { title },
    } = tutorialGroup;

    return (
        <div className={card}>
            <div className={imageWrapper}>
                <ImageItem image={image} srcSetSizes={srcSetSizes} />
                <h2 className={cardTitle}>{title}</h2>
            </div>
            <ul className={tutorialList}>
                {tutorials.map(item =>
                    item ? (
                        <li key={item.id} className={tutorialItem}>
                            <Link
                                to={`tutorials/${item.id}`}
                                className={tutorialItemLink}
                            >
                                {item.content.title}
                            </Link>
                        </li>
                    ) : null,
                )}
            </ul>
        </div>
    );
};

export default TutorialGroupCard;
