import React, { useState } from "react";
import {
    iconLink,
    sharingButtonContainer,
} from "./SocialMediaShareLink.css.js";
import {
    capitalize,
    getLocalStorageItem,
    setLocalStorageItem,
} from "../../helpers/utils.js";
import type { SocialMedia } from "../../../_common/types.js";
import Icon from "../svg/Icon.js";
import SharingCounterBadge from "../svg/SharingCounterBadge.js";

interface ShareLinkProps {
    socialMedia: SocialMedia;
    id: string;
    href?: string;
    onClick: (incrementOnServer: boolean) => void;
    count: number | null;
    counterKey: string;
}
const timeInterval = 300000;
interface LocalCouterMeta {
    timestamp: number;
    counterValue: number;
}
const saveCounterMeta = (key: string, data: LocalCouterMeta): void => {
    setLocalStorageItem(key, JSON.stringify(data));
};
const getCounterMeta = (key: string): LocalCouterMeta | undefined => {
    const data = getLocalStorageItem(key);
    try {
        if (!data) {
            return undefined;
        }
        const parseData = JSON.parse(data);
        if (
            typeof parseData !== "object" ||
            typeof parseData.timestamp !== "number" ||
            typeof parseData.counterValue !== "number"
        ) {
            return undefined;
        }
        return parseData;
    } catch (error) {
        return undefined;
    }
};

const SocialMediaShareLink: React.FC<ShareLinkProps> = ({
    socialMedia,
    id,
    href,
    onClick,
    count,
    counterKey,
}) => {
    const [counterValue, setCounterValue] = useState<number>(count || 0);

    const counterMeta = getCounterMeta(counterKey);

    const onLinkClick = () => {
        const timestamp = Date.now();
        const shouldBeUpdated =
            !counterMeta || counterMeta.timestamp + timeInterval <= timestamp;
        onClick(shouldBeUpdated);

        if (shouldBeUpdated) {
            const newCounterValue = counterValue + 1;
            saveCounterMeta(counterKey, {
                timestamp,
                counterValue: newCounterValue,
            });
            setCounterValue(newCounterValue);
        }
    };

    const valueToDisplay = counterValue;
    return (
        <div className={sharingButtonContainer}>
            <a
                id={id}
                className={`${socialMedia}-link social-link ${iconLink}`}
                href={href}
                aria-label={capitalize(socialMedia)}
                onClick={onLinkClick}
                target="_blank"
                rel="noopener"
            >
                <Icon k={socialMedia} />
            </a>

            <SharingCounterBadge count={valueToDisplay} />
        </div>
    );
};
export default SocialMediaShareLink;
