import React, { useContext } from "react";

import StoreButtons from "../../common/StoreButtons.js";
import Content from "../../../i18n/content.js";
import { badgesContainer } from "./StoreButtonsContainer.css.js";

const StoreButtonsContainer: React.FC = () => {
    const { promoApps } = useContext(Content);

    return (
        <div className={badgesContainer}>
            <StoreButtons
                apps={promoApps}
                location="home"
                item="intro"
                place="intro"
            />
        </div>
    );
};

export default StoreButtonsContainer;
