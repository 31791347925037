import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import ConfigContext from "../../config/config-context.js";

interface Props {
    title: string;
    description: string;
    openGraphTwitterImageURL: string;
}

const HtmlTwitter: React.FC<Props> = ({
    title,
    description,
    openGraphTwitterImageURL,
}) => {
    const { twitterUsername } = useContext(ConfigContext);
    return (
        <Helmet>
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {twitterUsername ? (
                <meta name="twitter:site" content={twitterUsername} />
            ) : null}
            {twitterUsername ? (
                <meta name="twitter:creator" content={twitterUsername} />
            ) : null}
            <meta name="twitter:domain" content="starwalk.space" />
            <meta name="twitter:image" content={openGraphTwitterImageURL} />
            <meta name="twitter:image:alt" content={title} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    );
};

export default HtmlTwitter;
