import React from "react";
import { Helmet } from "react-helmet";
import { BRAND_NAME } from "../../../_common/content.js";

const HtmlInit: React.FC = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <meta name="google" content="notranslate" />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="manifest" href="/manifest.json" />
            <meta name="theme-color" content="#000019" />

            <title>{BRAND_NAME}</title>
        </Helmet>
    );
};

export default HtmlInit;
