import React, { useContext, useEffect } from "react";
import { ins } from "./GoogleAdSenseAdUnit.css.js";
import ConfigContext from "../../../config/config-context.js";

interface Props {
    adSlot: string;
    itemId: string;
    adFormat: string;
    style?: React.CSSProperties;
    fullLayout?: string;
    fullWidthResponsive?: string;
}

const GoogleAdSenseAdUnit: React.FC<Props> = ({
    adSlot,
    itemId,
    style,
    adFormat,
    fullLayout,
    fullWidthResponsive,
}) => {
    const { adSensePubId } = useContext(ConfigContext);

    useEffect(() => {
        try {
            if (typeof window !== "undefined") {
                ((window as any).adsbygoogle =
                    (window as any).adsbygoogle || []).push({});
            }
        } catch (e) {
            console.error("AdSense error:", e);
        }
    }, [adSlot, itemId]);

    if (!adSensePubId) return null;

    return (
        <ins
            className={`adsbygoogle ${ins}`}
            style={style ? style : { display: "block" }}
            data-ad-client={adSensePubId}
            data-ad-slot={adSlot}
            data-full-width-responsive={fullWidthResponsive}
            data-full-layout={fullLayout}
            data-ad-format={adFormat}
        />
    );
};

export default GoogleAdSenseAdUnit;
