import React from "react";
import { IImagePreview, Image } from "../../../graphql/queries/gallery.js";
import { WIDTH } from "../../styles/mediaQueries.js";
import { SrcSetSizes, makeBreakpoint } from "./ImageItem.js";
import ImageLoader from "./ImageLoader.js";

interface Props {
    image: Image | IImagePreview;
    srcSetSizes: SrcSetSizes;
    showPlaceholder?: boolean;
    noAspectWrapper?: boolean;
    children?: React.ReactNode;
    innerRef?: React.LegacyRef<HTMLImageElement | HTMLDivElement>;
}

const ImageItemRenderer: React.FC<Props> = ({
    image,
    srcSetSizes,
    showPlaceholder,
    children,
    noAspectWrapper,
    innerRef,
}) => {
    const { name, sourceUrl, thumbnails } = image.content;

    if (!thumbnails[0]) return null;

    const { width, height } = thumbnails[0];

    const src = `${sourceUrl}${thumbnails[0].fileName}`;

    const { desktop, laptop, tablet, largePhone, phone } = srcSetSizes;

    const sizes = [
        makeBreakpoint(WIDTH.desktop, desktop),
        makeBreakpoint(WIDTH.laptop, laptop),
        makeBreakpoint(WIDTH.tablet, tablet),
        makeBreakpoint(WIDTH.largePhone, largePhone),
        phone ? `${phone}px` : `100vw`,
    ].join("");

    const srcSet = thumbnails
        .map(
            thumbnail =>
                `${sourceUrl}${thumbnail.fileName} ${thumbnail.width}w`,
        )
        .join(", ");

    if (noAspectWrapper) {
        return (
            <img
                src={src}
                style={{ aspectRatio: `${width} / ${height}` }}
                srcSet={srcSet}
                sizes={sizes}
                alt={name || undefined}
                ref={innerRef as React.LegacyRef<HTMLImageElement>}
            />
        );
    }

    return (
        <ImageLoader
            src={src}
            aspectRatio={`${width}:${height}`}
            srcSet={srcSet}
            sizes={sizes}
            showPlaceholder={showPlaceholder}
            alt={name || undefined}
            innerRef={innerRef}
        >
            {children}
        </ImageLoader>
    );
};

export default ImageItemRenderer;
