import { ApolloClient } from "@apollo/client/core/ApolloClient.js";
import { openDB } from "idb";
import { PushSubscriptionApi } from "./push-subscription-api.js";
import { registerSW } from "./register-sw.js";
import { windowLoaded } from "../../helpers/utils.js";
import { Storage } from "./storage.js";

export const initPushSubscriptionApi = (
    apolloClient: ApolloClient<object>,
): PushSubscriptionApi | null => {
    if (
        typeof window === "undefined" ||
        !("serviceWorker" in navigator) ||
        !("PushManager" in window) ||
        !("Notification" in window) ||
        !("indexedDB" in window)
    ) {
        return null;
    }
    try {
        const storage = new Storage();
        const pushSubscriptionApi = new PushSubscriptionApi(
            apolloClient,
            storage,
            initSW(storage),
        );
        window.VTPushSubscriptionApi = pushSubscriptionApi;
        return pushSubscriptionApi;
    } catch (e) {
        console.error(e);
        return null;
    }
};

const initSW = async (storage: Storage): Promise<ServiceWorkerRegistration> => {
    await windowLoaded();
    const registration = await registerSW();
    // NOTE: next temp code should be removed after subscriptions update to custom SW
    const existingSubscriptionId = await storage.getSubscriptionId();
    if (!existingSubscriptionId) {
        const osSubscriptionId = await tryToGetOSSubscriptionId();
        if (osSubscriptionId) {
            await storage.setSubscriptionId(osSubscriptionId);
        }
    }
    // end of temp code
    return registration;
};

const tryToGetOSSubscriptionId = async (): Promise<string | undefined> => {
    try {
        const _db = await openDB("ONE_SIGNAL_SDK_DB", undefined, {
            upgrade(db) {
                db.createObjectStore("Ids");
            },
        });
        const res = await _db.get("Ids", "userId");
        if (res) {
            if (typeof res === "object") {
                return res.id;
            }
        }
    } catch (e) {
        return;
    }
};
