import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import { gaMarkdownLinkClick } from "../../../helpers/googleAnalytics.js";
import Content from "../../../i18n/content.js";
import { QuizForBanner } from "../../../graphql/queries/games.js";
import Icon from "../../svg/Icon.js";
import {
    bannerText,
    banner,
    button,
    icon,
    imageContainer,
    bannerContent,
} from "./common.css.js";
import MediaItem from "../../gallery/_common/MediaItem.js";

interface Props {
    item: QuizForBanner;
}
const QuizBanner: React.FC<Props> = ({ item }) => {
    const {
        id,
        content: { metaDescription, startButton, mediaItem },
    } = item;
    const { langCode } = useContext(Content);
    const { pathname } = useLocation();

    const to = `/quiz/${id}#0`;
    return (
        <div className={banner}>
            <Icon className={icon} k="handPoint" />
            <div className={bannerContent}>
                <div className={imageContainer}>
                    <MediaItem
                        mediaItem={mediaItem}
                        srcSetSizes={{
                            largePhone: 180,
                        }}
                    />
                </div>
                <div className={bannerText}>{metaDescription}</div>
            </div>
            <Link
                className={button}
                to={`/${langCode}${to}`}
                onClick={() =>
                    gaMarkdownLinkClick(
                        "internal_banner",
                        to,
                        langCode,
                        pathname,
                    )
                }
            >
                {startButton}
            </Link>
        </div>
    );
};

export default QuizBanner;
