export const MIN = 1000 * 60;
export const HOUR = MIN * 60;
export const DAY = HOUR * 24;
export const DAY_HALF = HOUR * 12;

export const tzOffsetsList = [
    -12, -11, -10, -9.5, -9, -8, -7, -6, -5, -4, -3.5, -3, -2, -1, 0, 1, 2, 3,
    3.5, 4, 4.5, 5, 5.5, 5.75, 6, 6.5, 7, 8, 8.75, 9, 9.5, 10, 10.5, 11, 12,
    12.75, 13, 14,
].map(v => v * HOUR);
