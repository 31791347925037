import React from "react";

import { container } from "../../styles/common.css.js";
import ScrollTracker from "../../common/ScrollTracker.js";
import BackSectionButtons from "./BackSectionButtons.js";
import {
    backLayerContainer,
    BackSectionName,
    backStickyContainer,
    badgesContainer,
    darkLayer,
    sectionContent,
    sectionStyleVariants,
    sectionText,
    sectionTitle,
} from "./BackSection.css.js";

interface Props {
    title: string;
    text: string;
    name: BackSectionName;
}

const BackSection: React.FC<Props> = ({ title, text, name }) => {
    const triggerId = `${name}-dl`;

    return (
        <div className={backLayerContainer} id={triggerId}>
            <ScrollTracker
                position="50%"
                eventName={`${name}_section_visible`}
            />
            <div className={backStickyContainer}>
                <section className={sectionStyleVariants[name]}>
                    <div className={darkLayer} />
                    <div className={container.flex}>
                        <div className={sectionContent}>
                            <h2 className={sectionTitle}>{title}</h2>
                            <p className={sectionText}>{text}</p>
                            <div className={badgesContainer}>
                                <BackSectionButtons name={name} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default BackSection;
