import React from "react";
import Icon from "../../svg/Icon.js";

interface Props {
    state: "empty" | "correct" | "wrong";
}

const colorMap = {
    empty: "grey",
    correct: "green",
    wrong: "red",
};

const VariantIcon: React.FC<Props> = ({ state }) => (
    <Icon k={state} style={{ color: colorMap[state] }} />
);

export default VariantIcon;
