import React from "react";
import { Helmet } from "react-helmet";
import { QA } from "../../graphql/queries/_common.js";

interface Props {
    faq: QA[];
}

const HtmlFaqStructuredData: React.FC<Props> = ({ faq }) => {
    const data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: faq.map(({ q, a }) => ({
            "@type": "Question",
            name: q,
            acceptedAnswer: {
                "@type": "Answer",
                text: a,
            },
        })),
    };

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(data)}</script>
        </Helmet>
    );
};
export default HtmlFaqStructuredData;
