import { useContext, useState } from "react";
import DateFormatter from "../common/DateFormatter.js";
import DatePickerForm from "./forms/DatePickerForm.js";
import {
    DateSetterContext,
    PickedDateContext,
} from "./contexts/DateContextProvider.js";
import {
    buttonStyle,
    dateStyle,
    pickedDateContainer,
} from "./PickedDate.css.js";
import Icon from "../svg/Icon.js";
import { highlightedIcon } from "./GeoLocationPicker.css.js";

const PickedDate: React.FC = () => {
    const dispatch = useContext(DateSetterContext);
    const { todayDate, pickedDate } = useContext(PickedDateContext);
    const [isOpenedForm, setIsOpenedForm] = useState<boolean>(false);

    return (
        <div className={pickedDateContainer}>
            <button
                onClick={() => setIsOpenedForm(true)}
                className={buttonStyle}
            >
                <Icon k="calendar" className={highlightedIcon} />
            </button>
            <DateFormatter
                className={dateStyle}
                v={pickedDate}
                onClick={() => setIsOpenedForm(true)}
            />
            <button
                className={buttonStyle}
                disabled={todayDate === pickedDate}
                onClick={() => dispatch({ type: "set_today" })}
            >
                <Icon k="resetDate" className={highlightedIcon} />
            </button>
            {isOpenedForm ? (
                <DatePickerForm onClose={() => setIsOpenedForm(false)} />
            ) : null}
        </div>
    );
};

export default PickedDate;
