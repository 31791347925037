import React from "react";
import { Helmet } from "react-helmet";
import { COMPANY_NAME } from "../../../_common/content.js";
import { ASSETS_PATH } from "../../config/content.js";
import { findClosestThumbnail } from "../../../_common/utils.js";
import { Image } from "../../graphql/queries/gallery.js";

interface Props {
    title: string;
    description: string;
    pageUrl: string;
    image: Image;
    datePublished: string;
    dateModified: string;
    authorType: "Person" | "Organization";
    authorName: string;
}

const HtmlNewsStructuredData: React.FC<Props> = ({
    title,
    description,
    image,
    pageUrl,
    dateModified,
    datePublished,
    authorType,
    authorName,
}) => {
    const thumbnail = findClosestThumbnail(image.content.thumbnails, 3000);
    const data = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
            "@type": "WebPage",
            "@id": pageUrl,
        },
        name: title,
        headline: title,
        image: [`${image.content.sourceUrl}${thumbnail.fileName}`],
        datePublished,
        dateModified,
        author: {
            "@type": authorType,
            name: authorName,
        },
        publisher: {
            "@type": "Organization",
            name: COMPANY_NAME,
            logo: {
                "@type": "ImageObject",
                url: `${ASSETS_PATH}/icons/icon-512x512.png`,
                width: 512,
                height: 512,
            },
        },
        description,
        speakable: {
            "@type": "SpeakableSpecification",
            cssSelector: ["headline", "introduction"],
        },
    };
    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(data)}</script>
        </Helmet>
    );
};

export default HtmlNewsStructuredData;
