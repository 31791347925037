import React from "react";
import { img } from "./ImageLoader.css.js";
import AspectRatio from "./AspectRatio.js";

interface Props {
    aspectRatio: string;
    showPlaceholder?: boolean;
    src: string;
    srcSet?: string;
    sizes?: string;
    alt?: string;
    children?: React.ReactNode;
    innerRef?: React.LegacyRef<HTMLDivElement>;
}

const ImageLoader: React.FC<Props> = ({
    aspectRatio,
    src,
    alt,
    srcSet,
    sizes,
    showPlaceholder,
    children,
    innerRef,
}) => {
    // const [isLoaded, setIsLoaded] = useState(true);
    return (
        <AspectRatio
            v={aspectRatio}
            showPlaceholder={showPlaceholder}
            innerRef={innerRef}
        >
            <img
                className={img}
                key={src}
                src={src}
                alt={alt}
                srcSet={srcSet}
                sizes={sizes}
                // isLoaded={isLoaded}
                // onLoad={() => setIsLoaded(true)}
            />
            {children}
        </AspectRatio>
    );
};

export default ImageLoader;
