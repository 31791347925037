import React from "react";
import Icon from "../svg/Icon.js";
import { messageContainerStyles, styledIconVariants } from "./Message.css.js";

interface Props {
    messageValue: string;
    isSmall?: boolean;
}
const Message: React.FC<Props> = ({ messageValue, isSmall }) => {
    return (
        <div
            className={
                isSmall
                    ? messageContainerStyles.small
                    : messageContainerStyles.large
            }
        >
            <Icon
                className={
                    isSmall
                        ? styledIconVariants.small
                        : styledIconVariants.large
                }
                k="search"
            />
            <div>{messageValue}</div>
        </div>
    );
};

export default Message;
