export const ASSETS_PATH = "/assets";

// const altGetAppButtonTexts: { [langCode: string]: string[] } = {
//     de: [
//         `Entdecke %{APP_NAME}`,
//         `Probiere %{APP_NAME} aus`,
//         `%{APP_NAME} ausprobieren`,
//         `%{APP_NAME} entdecken`,
//         `Probieren Sie %{APP_NAME} aus`,
//         `Entdecken Sie %{APP_NAME}`,
//     ],
//     ja: [
//         `%{APP_NAME}を試す`,
//         `%{APP_NAME}をダウンロードする`,
//         `今すぐ%{APP_NAME}をダウンロードする`,
//         `%{APP_NAME}を手に入れる`,
//         `%{APP_NAME}を入手`,
//     ],
//     en: [
//         `Try %{APP_NAME}`,
//         `Try %{APP_NAME} now`,
//         `Download %{APP_NAME}`,
//         `Explore %{APP_NAME}`,
//         `Enjoy %{APP_NAME}`,
//         `Get %{APP_NAME} now`,
//     ],
//     pt: [
//         `Experimente %{APP_NAME}`,
//         `Descubra %{APP_NAME}`,
//         `Obtenha %{APP_NAME}`,
//         `Baixe %{APP_NAME}`,
//         `Obtenha %{APP_NAME} agora`,
//         `Baixe %{APP_NAME} agora`,
//     ],
//     es: [
//         `Prueba %{APP_NAME}`,
//         `Descubre %{APP_NAME}`,
//         `Obtén %{APP_NAME}`,
//         `Descarga %{APP_NAME}`,
//         `Obtén %{APP_NAME} ahora`,
//         `Descarga %{APP_NAME} ahora`,
//     ],
//     ko: [
//         `%{APP_NAME}으로 천문학 배우기`,
//         `%{APP_NAME} 사용해보세요`,
//         `%{APP_NAME} 사용해 보셨나요?`,
//         `%{APP_NAME}으로 별보기 시작`,
//         `%{APP_NAME} 다운로드하기`,
//         `%{APP_NAME} 다운로드해보세요`,
//         `지금 바로 %{APP_NAME} 받기`,
//     ],
//     "zh-Hans": [
//         `试试%{APP_NAME}`,
//         `试试%{APP_NAME}应用`,
//         `下载%{APP_NAME}`,
//         `下载%{APP_NAME}软件`,
//         `立即下载%{APP_NAME}`,
//     ],
//     "zh-Hant": [
//         `試試%{APP_NAME}`,
//         `試試%{APP_NAME}應用`,
//         `下載%{APP_NAME}`,
//         `下載%{APP_NAME}軟件`,
//         `立即下載%{APP_NAME}`,
//     ],
// };

// export const pickAltGetAppButtonText = (
//     defaultText: string,
//     langCode: LangCode,
//     variant?: number,
// ): string => {
//     if (!variant) return defaultText;
//     return altGetAppButtonTexts[langCode]?.[variant - 1] || defaultText;
// };

export const defaultLinkRel = "nofollow noopener noreferrer";
