import {
    SWCommands,
    SW_PATH,
    SW_VERSION as EXPECTED_SW_VERSION,
} from "../../../_common/config.js";
import { sleep } from "../../helpers/utils.js";

export const registerSW = async (): Promise<ServiceWorkerRegistration> => {
    try {
        const registration = await navigator.serviceWorker.register(SW_PATH);

        if (!registration.active) {
            await waitSWActivation(registration);
            await sleep(2);
        }

        const swVersion = await getSWVersion(registration);

        if (swVersion !== EXPECTED_SW_VERSION) {
            await registration.update();
            await sleep(3);
            registration.active?.postMessage({
                command: SWCommands.CheckWorkerVersion,
            });
        }

        return registration;
    } catch (e) {
        console.error(`Unable to register service worker ${e}`);
        throw e;
    }
};

const waitSWActivation = async (
    registration: ServiceWorkerRegistration,
): Promise<ServiceWorker> => {
    if (registration.active) return registration.active;
    for (let s = 1; s <= 10; s++) {
        await sleep(s);
        if (registration.active) return registration.active;
    }
    throw new Error("Service Worker is not active.");
};

export const getSWVersion = async (
    swRegistration: ServiceWorkerRegistration,
): Promise<string> => {
    const activeSW = swRegistration.active;

    if (!activeSW) return "sw-not-active";

    return new Promise(res => {
        const timer = setTimeout(() => {
            res("unknown");
            unlisten();
        }, 5000);
        const listener = (e: MessageEvent) => {
            if (e.data && e.data.command === SWCommands.GetWorkerVersion) {
                res(`${e.data.payload}`);
                unlisten();
                clearTimeout(timer);
            }
        };
        const unlisten = () => {
            navigator.serviceWorker.removeEventListener("message", listener);
        };
        navigator.serviceWorker.addEventListener("message", listener);
        activeSW.postMessage({ command: SWCommands.GetWorkerVersion });
    });
};
