import React from "react";
import DateContextProvider from "./contexts/DateContextProvider.js";
import GeoLocationContextProvider, {
    CityGeoLocation,
} from "./contexts/GeoLocationContextProvider.js";
import SolarObjectsContextProvider from "./contexts/SolarObjectsContextProvider.js";
import RenderContextProvider from "./contexts/RenderContextProvider.js";
import TimezoneContextProvider from "./contexts/TimezoneContextProvider.js";

interface Props {
    children: React.ReactNode;
    defaultGeoLocation: CityGeoLocation;
}

const MoonCalendarContexts: React.FC<Props> = ({
    children,
    defaultGeoLocation,
}) => {
    return (
        <TimezoneContextProvider defaultTimezone={defaultGeoLocation.timezone}>
            <DateContextProvider>
                <SolarObjectsContextProvider
                    defaultGeoLocation={{
                        latitude: defaultGeoLocation.latitude,
                        longitude: defaultGeoLocation.longitude,
                        altitude: defaultGeoLocation.altitude,
                    }}
                >
                    <GeoLocationContextProvider>
                        <RenderContextProvider>
                            {children}
                        </RenderContextProvider>
                    </GeoLocationContextProvider>
                </SolarObjectsContextProvider>
            </DateContextProvider>
        </TimezoneContextProvider>
    );
};

export default MoonCalendarContexts;
