import {
    calendarGrid,
    calendarHeaderCell,
    gridBackground,
} from "./CalendarGrid.css.js";
import CalendarCell from "./CalendarCell.js";
import { useContext } from "react";
import { PickedMonthContext } from "./contexts/DateContextProvider.js";
import { calendarCell } from "./CalendarCell.css.js";
import { getWeekDays } from "../../helpers/utils.js";
import Content from "../../i18n/content.js";

type WeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

type CellConfig = {
    day: number;
    date: number;
} | null;

const weekDayMap: { [key: number]: WeekDay } = {
    0: 7,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
};

const fillMonthGrid = (firstDayOfMonth: number): CellConfig[] => {
    const d = new Date(firstDayOfMonth);
    const month = d.getUTCMonth();
    const config: CellConfig[] = [];
    for (let i = 0; i < weekDayMap[d.getUTCDay()] - 1; i++) {
        config.push(null);
    }
    let lastWeekDay: number = 0;
    while (d.getUTCMonth() === month) {
        config.push({
            day: d.getUTCDate(),
            date: d.getTime(),
        });
        lastWeekDay = weekDayMap[d.getUTCDay()];
        d.setUTCDate(d.getUTCDate() + 1);
    }
    for (let i = 0; i < 7 - lastWeekDay; i++) {
        config.push(null);
    }
    return config;
};

const CalendarGrid: React.FC = () => {
    const pickedMonth = useContext(PickedMonthContext);

    const cells = fillMonthGrid(pickedMonth);

    return (
        <div className={gridBackground}>
            <div className={calendarGrid}>
                <WeekDays />
                {cells.map((c, i) =>
                    c === null ? (
                        <div key={"empty_cell_" + i} className={calendarCell} />
                    ) : (
                        <CalendarCell key={c.date} date={c.date} day={c.day} />
                    ),
                )}
            </div>
        </div>
    );
};

const WeekDays: React.FC = () => {
    const { langCode } = useContext(Content);
    return (
        <>
            {getWeekDays(langCode).map(weekDayName => (
                <div key={weekDayName} className={calendarHeaderCell}>
                    {weekDayName}
                </div>
            ))}
        </>
    );
};

export default CalendarGrid;
