import React from "react";
import Markdown from "react-markdown";

import {
    ReactBaseProps,
    ReactMarkdownProps,
} from "react-markdown/src/ast-to-react.js";
import slug from "remark-slug";
import PromoBanner from "./banners/PromoBanner.js";
import { PromoItem } from "../../graphql/queries/_common.js";
import markdownHandling from "../../plugins/markdown/MarkdownHandlingPlugin.js";
import { markdownDefaultStyle } from "./MarkdownRenderer.css.js";
import { Image } from "../../graphql/queries/gallery.js";
import ImageItem from "../gallery/_common/ImageItem.js";
import ImageWithCaption from "../gallery/_common/ImageWithCaption.js";
import MarkdownLink from "./MarkdownLink.js";
import MarkdownParagraph from "./MarkdownParagraph.js";
import CustomAdUnit from "./banners/CustomAdUnit.js";
import { NewsCustomBanner } from "../../graphql/queries/news.js";

const makeImg =
    (images: Image[]): React.FC<ReactBaseProps> =>
    ({ src }) => {
        const image = images.find(i => i.id === src);
        if (!image) return null;
        return (
            <ImageItem
                noAspectWrapper
                image={image}
                srcSetSizes={{
                    laptop: 968,
                    tablet: 736,
                }}
            />
        );
    };

const promoItemTypeMap = {
    Infographics: "infographics",
    Quiz: "quiz",
};

interface Props {
    source: string;
    className?: string;
    tocHeader?: string | null;
    promoItems?: PromoItem[];
    adBanner?: React.ReactElement;
    adBannerUnderToC?: React.ReactElement;
    addClassnameToFirstParagraph?: boolean;
    images?: Image[];
    customBanners?: NewsCustomBanner[] | null;
}
const MarkdownRenderer: React.FC<Props> = ({
    source,
    className,
    tocHeader,
    promoItems,
    adBanner,
    adBannerUnderToC,
    addClassnameToFirstParagraph,
    images,
    customBanners,
}) => {
    const promoItemsMap: { [id: string]: PromoItem } | undefined =
        promoItems && promoItems.length
            ? promoItems.reduce<{ [id: string]: PromoItem }>((map, i) => {
                  map[`${promoItemTypeMap[i.__typename]}/${i.id}`] = i;
                  return map;
              }, {})
            : undefined;

    const Code: React.FC<ReactBaseProps & ReactMarkdownProps> = ({
        children,
    }) => {
        if (
            children &&
            children.length === 1 &&
            typeof children[0] === "string"
        ) {
            const value = children[0];

            if (value === "in-article-ad-block") {
                return adBanner ? adBanner : null;
            }
            if (value === "in-article-ad-block-under-toc") {
                return adBannerUnderToC ? adBannerUnderToC : null;
            }
            if (promoItemsMap && value.startsWith("promo-banner")) {
                const promoItemId = value.split(":")[1];
                return <PromoBanner promoItem={promoItemsMap[promoItemId]} />;
            }
            if (images && value.startsWith("image-with-caption:")) {
                const imageId = value.split(":")[1];
                const image = images.find(i => i.id === imageId);
                if (!image) return null;
                return (
                    <ImageWithCaption
                        image={image}
                        srcSetSizes={{
                            laptop: 968,
                            tablet: 736,
                        }}
                    />
                );
            }
            if (value.startsWith("custom-ad-unit:")) {
                const customAdUnitName = value.split(":")[1];
                return (
                    <CustomAdUnit
                        name={customAdUnitName}
                        customBanners={customBanners}
                    />
                );
            }
        }
        // return null;
        return <code>{children}</code>;
    };

    const plugins = [
        slug,
        [
            markdownHandling,
            {
                tocHeader,
                promoItemPaths: promoItemsMap
                    ? Object.keys(promoItemsMap)
                    : undefined,
                injectAdBanner: !!adBanner,
                addClassnameToFirstParagraph,
                processBlockqoutedImages: !!images,
            },
        ],
    ];
    return (
        <Markdown
            remarkPlugins={plugins}
            className={`${markdownDefaultStyle} ${className}`}
            includeElementIndex={true}
            children={source}
            skipHtml={true}
            transformLinkUri={uri => {
                if (uri && uri.startsWith("self:")) {
                    return "#";
                }
                return Markdown.uriTransformer(uri);
            }}
            components={{
                a: MarkdownLink,
                code: Code,
                img: images ? makeImg(images) : undefined,
                p: MarkdownParagraph,
            }}
        />
    );
};

export default React.memo(MarkdownRenderer, (prev, next) => {
    return prev.source === next.source;
});
