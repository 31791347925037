// Constant for media queries:
import { StyleRule } from "@vanilla-extract/css";
// breakpoints are taken from here https://getbootstrap.com/docs/5.3/layout/breakpoints/#available-breakpoints
export const WIDTH = {
    largePhone: 576, // was 400
    tablet: 768, // was 568
    laptop: 992, // was 900
    desktop: 1200,
};
export const responsiveStyle = ({
    largePhone,
    tablet,
    laptop,
    desktop,
}: {
    largePhone?: StyleRule;
    tablet?: StyleRule;
    laptop?: StyleRule;
    desktop?: StyleRule;
}) => {
    const result: { [x: string]: StyleRule } = {};
    if (largePhone) {
        result[`screen and (min-width: ${WIDTH.largePhone}px)`] = largePhone;
    }
    if (tablet) {
        result[`screen and (min-width: ${WIDTH.tablet}px)`] = tablet;
    }
    if (laptop) {
        result[`screen and (min-width: ${WIDTH.laptop}px)`] = laptop;
    }
    if (desktop) {
        result[`screen and (min-width: ${WIDTH.desktop}px)`] = desktop;
    }
    return {
        "@media": result,
    };
};
