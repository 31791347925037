import { openDB, IDBPDatabase, DBSchema } from "idb";
import {
    STORAGE_DB_NAME,
    STORAGE_DB_VERSION,
} from "../../../_common/config.js";

interface StorageSchema extends DBSchema {
    Ids: {
        key: string;
        value: string;
    };
    Session: {
        key: string;
        value: number;
    };
}

const HOUR = 60 * 60 * 1000;
const SAVE_DELAY = HOUR; // 1 hour
const SESSION_PERIOD = HOUR / 2; // 30 mins
const SUBSCRIPTION_PROPOSE_DELAY = HOUR * 24; // 1 day
const MAX_ALERT_APPEARANCE_COUNT = 10;

export class Storage {
    private db: Promise<IDBPDatabase<StorageSchema>>;

    constructor() {
        this.db = openDB<StorageSchema>(STORAGE_DB_NAME, STORAGE_DB_VERSION, {
            upgrade: _db => {
                if (!_db.objectStoreNames.contains("Ids")) {
                    _db.createObjectStore("Ids");
                }
                if (!_db.objectStoreNames.contains("Session")) {
                    _db.createObjectStore("Session");
                }
            },
        });
    }

    public async getSubscriptionId(): Promise<string | undefined> {
        return (await this.db).get("Ids", "PushSubscriptionId");
    }

    public async setSubscriptionId(id: string) {
        (await this.db).put("Ids", id, "PushSubscriptionId");
    }

    // public async getSWVersion(): Promise<string> {
    //     const result = await (await this.db).get("Ids", "CurrentSWVersion");
    //     return result || "unset";
    // }

    public async shouldSavePushSubscriptionToAPI(): Promise<boolean> {
        const t = await this.getIntItem("LastSave");
        return Date.now() - t > SAVE_DELAY;
    }

    public async successfullySavedPushSubscriptionToAPI(
        subscriptionId: string,
    ): Promise<void> {
        this.setIntItem("LastSave", Date.now());
        this.setIntItem("SessionCounter", 0);
        const id = await this.getSubscriptionId();
        if (id !== subscriptionId) {
            this.setSubscriptionId(subscriptionId);
        }
    }

    public async registerAction(timestamp: number) {
        const subscriptionId = await this.getSubscriptionId();
        if (!subscriptionId) return;
        const lastActionAt = await this.getIntItem("LastAction");
        if (timestamp - lastActionAt > SESSION_PERIOD) {
            await this.incrIntItem("SessionCounter");
        }
        if (timestamp > lastActionAt) {
            await this.setIntItem("LastAction", timestamp);
        }
    }

    public async getLastAction(): Promise<string> {
        return new Date(await this.getIntItem("LastAction")).toISOString();
    }

    public async getSessionCounter(): Promise<number> {
        return await this.getIntItem("SessionCounter");
    }

    public async shouldShowSubscriptionAlert(): Promise<boolean> {
        const showedCount = await this.getIntItem("ShowedAlertCount");
        const lastShowedAt = await this.getIntItem("ShowedAlertAt");
        return (
            showedCount < MAX_ALERT_APPEARANCE_COUNT &&
            Date.now() - lastShowedAt > SUBSCRIPTION_PROPOSE_DELAY * showedCount
        );
    }

    public async showedAlert(): Promise<void> {
        await this.incrIntItem("ShowedAlertCount");
        await this.setIntItem("ShowedAlertAt", Date.now());
    }

    private async incrIntItem(key: string): Promise<number> {
        let current = await this.getIntItem(key);
        await this.setIntItem(key, ++current);
        return current;
    }

    private async getIntItem(key: string): Promise<number> {
        const v = await (await this.db).get("Session", key);
        if (!v || typeof v !== "number") return 0;
        if (!Number.isInteger(v) && !(v > 0)) return 0;
        return v;
    }
    private async setIntItem(key: string, val: number): Promise<IDBValidKey> {
        return (await this.db).put("Session", val, key);
    }
}
