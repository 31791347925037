import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import { useLocation } from "react-router";

// Config:
import { COPYRIGHT_LINK } from "../../../_common/content.js";
import { DOMAIN_URL } from "../../config/env.js";

// Queries:
import {
    GET_NEWS,
    GetNewsVariables,
    GetNewsData,
} from "../../graphql/queries/news.js";

// Components:
import Spinner from "../Spinner.js";
import MetaTags from "../MetaTags.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import NewsPage from "./NewsPage.js";
import HtmlNewsStructuredData from "../helmet/HtmlNewsStructuredData.js";
import HtmlFaqStructuredData from "../helmet/HtmlFaqStructuredData.js";

// Types & Interfaces:
import Content from "../../i18n/content.js";
import ConfigContext from "../../config/config-context.js";
import { PromoAppSetterContext } from "../layout/PromoAppContextProvider.js";
import HtmlBreadcrumbsStructuredData from "../helmet/HtmlBreadcrumbsStructuredData.js";
import { SOCIAL_MEDIA_LINKS } from "../common/SocialMediaLinks.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";

interface Props {
    newsID: string;
    path: string;
}

const News: React.FC<Props> = ({ newsID, path }) => {
    const { search } = useLocation();
    const { langCode } = useContext(Content);
    const { recommendedNewsCount } = useContext(ConfigContext);
    const pickPromoApp = useContext(PromoAppSetterContext);

    const searchParams = new URLSearchParams(search);

    const isPreview = searchParams.get("preview") === "1";

    const { loading, error, data } = useQuery<GetNewsData, GetNewsVariables>(
        GET_NEWS,
        {
            variables: {
                newsChannel: isPreview ? "preview" : undefined,
                langCode: langCode,
                id: newsID,
                featuredFirst: recommendedNewsCount,
            },
        },
    );

    useEffect(() => {
        if (data?.news?.promoApps) {
            pickPromoApp({
                type: "pick",
                apps: data.news.promoApps,
            });
        }
    }, [data, pickPromoApp]);

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (loading) {
        return <Spinner />;
    }

    if (data && data.news) {
        const {
            news: {
                content,
                contentLangs,
                copyright,
                id,
                updatedAt,
                date,
                featuredNews,
                image,
            },
        } = data;

        const { promoApps } = data.news;

        const iosApp = promoApps.find(app => app.platform === "ios");

        return (
            <>
                <MetaTags
                    currentLang={langCode}
                    langs={contentLangs}
                    path={path}
                    metaTitle={content.metaTitle}
                    title={content.title}
                    description={content.preview}
                    promoAppId={iosApp ? iosApp.marketId : undefined}
                    mediaItem={image}
                    noindex={isPreview}
                    keywords={content.keywords}
                    largeImagePreview
                    publishedAt={date}
                    updatedAt={updatedAt}
                    publisher={SOCIAL_MEDIA_LINKS["facebook"]}
                />
                <HtmlNewsStructuredData
                    title={content.title}
                    description={content.preview}
                    image={image}
                    pageUrl={`${DOMAIN_URL}/${langCode}/news/${id}`}
                    authorName={copyright.author || copyright.url}
                    authorType={
                        !copyright.url.includes(COPYRIGHT_LINK)
                            ? "Person"
                            : "Organization"
                    }
                    dateModified={updatedAt}
                    datePublished={date}
                />
                {content.faq && <HtmlFaqStructuredData faq={content.faq} />}
                <HtmlBreadcrumbsStructuredData
                    pageTitle={content.title}
                    sectionName="news"
                />
                <NewsPage
                    news={data.news}
                    apps={promoApps}
                    pagePath={path}
                    featuredNews={featuredNews}
                />
            </>
        );
    }

    return <NotFoundErrorMsg />;
};

export default News;
