import React, { useContext } from "react";

import { skyObjects } from "./sky-objects.js";
import { ASSETS_PATH } from "../../../config/content.js";
import Icon from "../../svg/Icon.js";
import { container } from "../../styles/common.css.js";
import ScrollTracker from "../../common/ScrollTracker.js";
import { gaSkyObjectHomeClick } from "../../../helpers/googleAnalytics.js";
import ImageLoader from "../../gallery/_common/ImageLoader.js";
import {
    metaInfo,
    cover,
    reversedIcon,
    imageContainer,
    info,
    list,
    listItem,
    meta,
    spanItem,
} from "./ObservingSection.css.js";
import {
    commonSection,
    commonSectionText,
    commonSectionTitle,
    sectionContent,
    titlesContainer,
} from "./homeCommon.css.js";
import Content from "../../../i18n/content.js";

interface Props {
    title: string;
    text: string;
}

const ObservingSection: React.FC<Props> = ({ title, text }) => {
    return (
        <section className={commonSection}>
            <div className={container.flex}>
                <div className={sectionContent}>
                    <ScrollTracker
                        position="50%"
                        eventName="observing_section_visible"
                    />
                    <div className={titlesContainer}>
                        <h2 className={commonSectionTitle}>{title}</h2>
                        <p className={commonSectionText}>{text}</p>
                    </div>
                    <SkyObjectsList />
                </div>
            </div>
        </section>
    );
};

const SkyObjectsList: React.FC = () => {
    const { langCode } = useContext(Content);
    return (
        <div className={list}>
            {skyObjects
                .slice(0, 8)
                .map(
                    ({
                        id,
                        image,
                        name,
                        category,
                        angle,
                        riseTime,
                        setTime,
                    }) => (
                        <div
                            className={listItem}
                            key={id}
                            onClick={() => {
                                gaSkyObjectHomeClick(id, langCode);
                            }}
                        >
                            <div className={imageContainer}>
                                <ImageLoader
                                    aspectRatio="1:1"
                                    src={`${ASSETS_PATH}/observing-list/${image}`}
                                    alt={name}
                                    showPlaceholder={false}
                                />
                            </div>
                            <div className={info}>
                                {angle < 0 && <div className={cover} />}

                                <span>{name}</span>
                                <span className={metaInfo}>{category}</span>
                                <div className={meta}>
                                    <Icon k="angle" />
                                    <span className={spanItem}>{angle}</span>
                                    {riseTime && setTime && (
                                        <>
                                            <Icon
                                                k="arrowUp"
                                                className={
                                                    angle > 0
                                                        ? reversedIcon
                                                        : undefined
                                                }
                                            />
                                            <span className={spanItem}>
                                                {angle < 0 ? riseTime : setTime}
                                            </span>
                                            <Icon
                                                k="arrowUp"
                                                className={
                                                    angle < 0
                                                        ? reversedIcon
                                                        : undefined
                                                }
                                            />
                                            <span className={spanItem}>
                                                {angle > 0 ? riseTime : setTime}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    ),
                )}
        </div>
    );
};

export default ObservingSection;
