import gql from "graphql-tag";
import type { LangCode } from "../../../_common/types.js";
import { mediaItem, thumbnail } from "./fragments.js";
import { IMediaItem } from "./gallery.js";

export interface GetStaticContentVariables {
    id: string;
    langCode?: LangCode;
}

export interface StaticContent<T, M = IMediaItem> {
    id: string;
    contentLangs: LangCode[];
    content: T;
    mediaItems: (M | null)[] | null;
}

export interface GetStaticContentData<T, M = IMediaItem> {
    staticContent: StaticContent<T, M> | null;
}

export const GET_STATIC_CONTENT = gql`
    query getStaticContent($id: StaticContentID!, $langCode: LangCode) {
        staticContent(id: $id) {
            id
            contentLangs
            content(langCode: $langCode)
            mediaItems {
                ...mediaItem
            }
        }
    }
    ${mediaItem}
    ${thumbnail}
`;
