import type { Thumbnail } from "./types.js";

// TODO: improve findClosesThumbnail logic
export const findClosestThumbnail = (
    thumbnails: Thumbnail[],
    minWidth: number = 1200,
): Thumbnail => {
    const thumbnail = thumbnails.reduce<Thumbnail>((prev, current) => {
        if (
            Math.abs(current.width - minWidth) < Math.abs(prev.width - minWidth)
        ) {
            return current;
        } else {
            return prev;
        }
    }, thumbnails[0]);
    return thumbnail;
};
