import React from "react";
import { absolutePoint } from "./AbsolutePoint.css.js";

export type PointPosition = "20vh" | "25%" | "50%" | "75%" | "95%";

interface Props {
    position: PointPosition;
    innerRef?: React.LegacyRef<HTMLDivElement>;
}

const AbsolutePoint: React.FC<Props> = ({ position, innerRef }) => (
    <div ref={innerRef} style={{ top: position }} className={absolutePoint} />
);

export default AbsolutePoint;
