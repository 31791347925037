import React from "react";

import { Image } from "../../../graphql/queries/gallery.js";
import MediaItem from "../../gallery/_common/MediaItem.js";
import { EngagementInfo } from "../../../graphql/queries/_common.js";
import ShareButtons from "../../common/ShareButtons.js";
import SummaryBadge from "./SummaryBadge.js";
import {
    mediaItemContainer,
    navButton,
    pageTitle,
    quizText,
    topicTags,
} from "./common.css.js";
import MarkdownRenderer from "../../common/MarkdownRenderer.js";
import { Topic } from "../../../graphql/queries/topics.js";
import TopicTags from "../../common/TopicTags.js";

interface Props {
    quizId: string;
    slug: string | null;
    title: string;
    text: string;
    mediaItem: Image;
    startButtonText: string;
    pagePath: string;
    onStartHandler: () => void;
    engagementInfo: EngagementInfo;
    usersCount: number | null;
    questionsNumber: number;
    avgScore: number | null;
    topics: (Topic | null)[];
}

const Intro: React.FC<Props> = ({
    quizId,
    slug,
    title,
    text,
    mediaItem,
    startButtonText,
    pagePath,
    onStartHandler,
    engagementInfo,
    usersCount,
    questionsNumber,
    avgScore,
    topics,
}) => {
    return (
        <>
            <h1 className={pageTitle}>{title}</h1>
            <TopicTags
                topics={topics}
                place="quiz_intro"
                cpath={`/quiz/${quizId}`}
                className={topicTags}
            />
            <MarkdownRenderer className={quizText} source={text} />
            <div className={mediaItemContainer}>
                <MediaItem
                    mediaItem={mediaItem}
                    showPlaceholder={false}
                    srcSetSizes={{
                        tablet: 614,
                    }}
                />
                <SummaryBadge
                    isIntro={true}
                    questionsNumber={questionsNumber}
                    avgScore={avgScore}
                    usersCount={usersCount}
                />
            </div>

            <button className={navButton} onClick={onStartHandler}>
                {startButtonText}
            </button>
            <ShareButtons
                pageTitle={title}
                position="quiz-intro"
                typename="Quiz"
                pagePath={pagePath}
                engagementInfo={engagementInfo}
                medium={quizId}
                slug={slug}
            />
        </>
    );
};

export default Intro;
