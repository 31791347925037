import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import ErrorPage from "./ErrorPage.js";

const RootErrorMsg: React.FC<RouteComponentProps> = ({ staticContext }) => {
    if (staticContext) {
        staticContext.statusCode = 500;
    }
    const title = "500";
    const message = "Oops, something bad happend. We are already fixing it...";
    return <ErrorPage title={title} message={message} />;
};

export default withRouter(RootErrorMsg);
