import React, { useContext } from "react";
import {
    moonViewContainer,
    hCanvas,
    mCanvas,
    moonViewCover,
    defaultImg,
    loaderContainer,
    switchButton,
    moonAspect,
} from "./MoonView.css.js";
import { CanvasRefsContext } from "./contexts/RenderContextProvider.js";
import Icon from "../svg/Icon.js";
import { rotatedIcon } from "./MonthSwitcher.css.js";
import { InitialRenderRefContext } from "../GlobalContextsProvider.js";
import CircleSpinner from "../common/CircleSpinner.js";
import {
    DateSetterContext,
    PickedDateContext,
} from "./contexts/DateContextProvider.js";
import { ASSETS_PATH } from "../../config/content.js";
import { makeSsrImageFileName } from "./utils.js";
import { useEffect } from "react";

const MoonView: React.FC = () => {
    const { mainCanvasRef, hiddenCanvasRef } = useContext(CanvasRefsContext);
    return (
        <div className={moonViewContainer}>
            <div className={moonAspect}>
                <canvas id="m-canv" className={mCanvas} ref={mainCanvasRef} />
                <canvas id="h-canv" className={hCanvas} ref={hiddenCanvasRef} />
                <SSRImgStub />
                <Loader />
                <DaySwitcher />
            </div>
        </div>
    );
};

const Loader: React.FC = () => {
    const { loaderRef } = useContext(CanvasRefsContext);

    return (
        <div
            ref={loaderRef}
            style={{ display: "none" }}
            className={loaderContainer}
        >
            <CircleSpinner />
        </div>
    );
};

const SSRImgStub: React.FC = () => {
    const { defaultImgRef } = useContext(CanvasRefsContext);
    const isInitRender = useContext(InitialRenderRefContext);
    const { pickedDate } = useContext(PickedDateContext);

    if (!isInitRender.current) return null;

    return (
        <img
            ref={defaultImgRef}
            className={defaultImg}
            alt="Current Moon Phase"
            src={`${ASSETS_PATH}/moon-calendar/phases/${makeSsrImageFileName(
                pickedDate,
                "m",
            )}`}
        />
    );
};

const DaySwitcher: React.FC = () => {
    const dispatch = useContext(DateSetterContext);

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === "ArrowLeft") {
                dispatch({ type: `prev_day` });
            }
            if (e.key === "ArrowRight") {
                dispatch({ type: `next_day` });
            }
        };
        if (document) {
            document.addEventListener("keydown", onKeyDown);
        }
        return () => {
            if (document) {
                document.removeEventListener("keydown", onKeyDown);
            }
        };
    }, []);
    return (
        <div className={moonViewCover}>
            <button
                onClick={() => {
                    dispatch({ type: `prev_day` });
                }}
                className={switchButton}
            >
                <Icon k="chevronL" />
            </button>
            <button
                onClick={() => {
                    dispatch({ type: `next_day` });
                }}
                className={switchButton}
            >
                <Icon className={rotatedIcon} k="chevronL" />
            </button>
        </div>
    );
};

export default MoonView;
