import React, { useContext, useEffect } from "react";

import ShareButtons from "../../common/ShareButtons.js";
import { Image } from "../../../graphql/queries/gallery.js";
import MediaItem from "../../gallery/_common/MediaItem.js";
import { EngagementInfo } from "../../../graphql/queries/_common.js";
import { App } from "../../../graphql/queries/apps.js";
import AppBanners from "../../common/banners/AppBanners.js";
import { gaTrackQuizEvent } from "../../../helpers/googleAnalytics.js";
import AppBannersContainer from "../../common/banners/AppBannersContainer.js";
import { restartButton, resultBlock } from "./Summary.css.js";
import {
    mediaItemContainer,
    navButton,
    pageTitle,
    quizText,
    topicTags,
} from "./common.css.js";
import MarkdownRenderer from "../../common/MarkdownRenderer.js";
import { Topic } from "../../../graphql/queries/topics.js";
import TopicTags from "../../common/TopicTags.js";
import Content from "../../../i18n/content.js";

interface Props {
    quizId: string;
    slug: string | null;
    result: string;
    res: string;
    title: string;
    text: string;
    mediaItem?: Image;
    apps: App[];
    restartButtonText: string;
    shareMessage: string;
    shareBlockTitle?: string;
    onRestartHandler: () => void;
    pagePath: string;
    engagementInfo: EngagementInfo;
    topics: (Topic | null)[];
}

const Summary: React.FC<Props> = ({
    quizId,
    slug,
    result,
    res,
    title,
    text,
    apps,
    mediaItem,
    pagePath,
    restartButtonText,
    shareMessage,
    shareBlockTitle,
    onRestartHandler,
    engagementInfo,
    topics,
}) => {
    const { langCode } = useContext(Content);
    useEffect(() => {
        gaTrackQuizEvent("ShowResult", quizId, langCode, res);
    }, [quizId, res]);

    return (
        <>
            <div className={resultBlock}>{result}</div>
            <h1 className={pageTitle}>{title}</h1>

            <MarkdownRenderer className={quizText} source={text} />
            <TopicTags
                topics={topics}
                place="quiz_summary"
                cpath={`/quiz/${quizId}`}
                className={topicTags}
            />
            <ShareButtons
                pageTitle={shareMessage}
                position={`quiz-result`}
                typename="Quiz"
                pagePath={pagePath}
                engagementInfo={engagementInfo}
                medium={quizId}
                label={`${quizId}_${res}`}
                title={shareBlockTitle}
                slug={slug}
            />
            <div className={mediaItemContainer}>
                <MediaItem
                    mediaItem={mediaItem || null}
                    showPlaceholder={false}
                    srcSetSizes={{
                        tablet: 614,
                    }}
                />
                <button
                    className={`${restartButton} ${navButton} restart-quiz-button`}
                    onClick={onRestartHandler}
                >
                    {restartButtonText}
                </button>
            </div>
            <AppBannersContainer>
                <AppBanners
                    promoApps={apps}
                    location={"quiz-result"}
                    item={quizId}
                />
            </AppBannersContainer>
        </>
    );
};

export default Summary;
