import { useContext } from "react";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import { SolarObjectsContext } from "./contexts/SolarObjectsContextProvider.js";
import { MoonCalendarContentContext } from "./MoonCalendar.js";
import { PickedDateContext } from "./contexts/DateContextProvider.js";
import { phaseDescriptionStyle, phaseName } from "./MoonPhase.css.js";

const MoonPhase: React.FC = () => {
    const moonCalendarContent = useContext(MoonCalendarContentContext);
    const { getPhase } = useContext(SolarObjectsContext);
    const { _pickedDate } = useContext(PickedDateContext);

    const phase = getPhase(_pickedDate);

    if (phase === "None") return null;

    return (
        <>
            <h2 className={phaseName}>{moonCalendarContent[`name${phase}`]}</h2>
            <MarkdownRenderer
                className={phaseDescriptionStyle}
                source={moonCalendarContent[`desc${phase}`]}
            />
        </>
    );
};

export default MoonPhase;
