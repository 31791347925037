import React from "react";

import { DOMAIN_URL } from "../config/env.js";
import { ASSETS_PATH } from "../config/content.js";
import HtmlTitle from "./helmet/HtmlTitle.js";
import HtmlDescription from "./helmet/HtmlDescription.js";
import HtmlAppleSmartBanner from "./helmet/HtmlAppleSmartBanner.js";
import HtmlCanonicalLink from "./helmet/HtmlCanonicalLink.js";
import HtmlAlternateLinks from "./helmet/HtmlAlternateLinks.js";
import HtmlOpenGraph from "./helmet/HtmlOpenGraph.js";
import HtmlTwitter from "./helmet/HtmlTwitter.js";
import HtmlNoIndex from "./helmet/HtmlNoIndex.js";
import type { LangCode } from "../../_common/types.js";
import { IImagePreview, IMediaItem } from "../graphql/queries/gallery.js";
import { findClosestThumbnail } from "../../_common/utils.js";
import HtmlKeywords from "./helmet/HtmlKeywords.js";
import HtmlLargeImagePreview from "./helmet/HtmlLargeImagePreview.js";

interface OGImage {
    url: string;
    width: number;
    height: number;
}

const DEFAULT_OG_IMAGE: OGImage = {
    url: `${ASSETS_PATH}/opengraph_card.jpg`,
    width: 1200,
    height: 630,
};

interface Props {
    metaTitle?: string | null;
    description: string;
    title: string;
    currentLang: LangCode;
    langs: LangCode[];
    path: string;
    mediaItem?: IMediaItem | IImagePreview | null;
    promoAppId?: string;
    noindex?: boolean;
    keywords?: string | null;
    largeImagePreview?: boolean;
    publishedAt?: string;
    updatedAt?: string;
    publisher?: string;
}

const makeOgImage = (
    mediaItem: IMediaItem | IImagePreview | null | undefined,
): OGImage => {
    if (!mediaItem) return DEFAULT_OG_IMAGE;
    if (mediaItem.__typename === "Image") {
        const ogImageThumbnail = findClosestThumbnail(
            mediaItem.content.thumbnails,
        );
        const ogImageSourceUrl = mediaItem.content.sourceUrl;
        return {
            url: ogImageSourceUrl + ogImageThumbnail.fileName,
            width: ogImageThumbnail.width,
            height: ogImageThumbnail.height,
        };
    }
    if (mediaItem.__typename === "YoutubeVideo") {
        const ogImageThumbnail = {
            fileName: "",
            width: 1280,
            height: 720,
            length: 100,
            contentType: "image/jpeg",
        };
        const ogImageSourceUrl = mediaItem?.content.thumbnailUrls[0];
        return {
            url: ogImageSourceUrl + ogImageThumbnail.fileName,
            width: ogImageThumbnail.width,
            height: ogImageThumbnail.height,
        };
    }
    return DEFAULT_OG_IMAGE;
};

const MetaTags: React.FC<Props> = ({
    currentLang,
    langs,
    path,
    metaTitle,
    description,
    title,
    mediaItem,
    promoAppId,
    noindex = false,
    keywords,
    largeImagePreview,
    publishedAt,
    updatedAt,
    publisher,
}) => {
    const isNotLocalized = !langs.includes(currentLang);
    const canonicalLang = isNotLocalized ? "en" : currentLang;
    const canonicalLink = `${DOMAIN_URL}/${canonicalLang + path}`;

    const skipIndexing = noindex || isNotLocalized;

    const ogImage = makeOgImage(mediaItem);

    return (
        <>
            <HtmlTitle value={metaTitle || title} />
            <HtmlDescription value={description} />
            {keywords && <HtmlKeywords value={keywords} />}
            {promoAppId && <HtmlAppleSmartBanner appId={promoAppId} />}
            {largeImagePreview ? <HtmlLargeImagePreview /> : null}
            {!skipIndexing ? (
                <>
                    <HtmlCanonicalLink value={canonicalLink} />
                    <HtmlAlternateLinks
                        domainURL={DOMAIN_URL}
                        path={path}
                        langs={langs}
                    />
                </>
            ) : (
                <HtmlNoIndex />
            )}
            <HtmlOpenGraph
                url={canonicalLink}
                title={title}
                description={description}
                imageURL={ogImage.url}
                imageWidth={ogImage.width}
                imageHeight={ogImage.height}
                publishedAt={publishedAt}
                updatedAt={updatedAt}
                publisher={publisher}
            />
            <HtmlTwitter
                title={title}
                description={description}
                openGraphTwitterImageURL={ogImage.url}
            />
        </>
    );
};

export default MetaTags;
