import React from "react";
import { Helmet } from "react-helmet";

interface Props {
    name: string;
    description: string;
    thumbnailUrl: string[];
    uploadDate: string;
    contentUrl: string;
}

const HtmlVideoStructuredData: React.FC<Props> = props => {
    const data = {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        ...props,
    };
    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(data)}</script>
        </Helmet>
    );
};

export default HtmlVideoStructuredData;
