const err = (errMsg: string) => {
    throw new Error(errMsg);
};
export const isDev = import.meta.env.DEV;
export const isProd = import.meta.env.PROD;
export const isSSR = import.meta.env.SSR;
export const MODE = import.meta.env.MODE;
export const DOMAIN_URL =
    import.meta.env.VITE_DOMAIN_URL ||
    err("VITE_DOMAIN_URL is required env var.");
export const VERSION = (import.meta.env.VITE_VERSION || "0.0.0").replace(
    "v",
    "",
);
