import React from "react";
import {
    MoonObjectCalculator,
    MoonPhase,
    PlanetNavigator,
    createSunBodyMath,
    createEarthBodyMath,
    createMoonBodyMath,
    Quaternion,
    Rad2Deg,
    SolarSystemObject as SSO,
    Vector3,
    timestampToJd,
} from "@vitotechnology/moon-position-ts";
import { GeoLocationBase } from "../../../graphql/queries/moon-calendar.js";

export interface SolarObjectsPositions {
    sun: Vector3;
    moon: Vector3;
    earth: Vector3;
    angle: number;
    moonRotation: Quaternion;
}

interface MoonAppearance {
    time: number;
    kind: "riseTime" | "culmTime" | "setTime";
}

interface MoonParams {
    age: number;
    illumination: number;
    angularSize: number;
    magnitude: number;
}

interface SolarObjectsPositionsContextState {
    getPositions: (date: number) => SolarObjectsPositions;
    getPhase: (date: number) => MoonPhase;
    getParams: (date: number) => MoonParams;
    getAppearances: (date: number) => MoonAppearance[];
    n: PlanetNavigator;
}

export const SolarObjectsContext =
    React.createContext<SolarObjectsPositionsContextState>({
        getPositions: () => ({
            sun: new Vector3(0, 0, 0),
            earth: new Vector3(0, 0, 0),
            moon: new Vector3(0, 0, 0),
            angle: 0,
            moonRotation: new Quaternion(0, 0, 0, 0),
        }),
        getPhase: () => MoonPhase.Full,
        getParams: () => ({
            age: 0,
            illumination: 0,
            angularSize: 0,
            magnitude: 0,
        }),
        getAppearances: () => [],
        n: {} as PlanetNavigator,
    });

interface Props {
    children: React.ReactNode;
    defaultGeoLocation: GeoLocationBase;
}

const mins = (fromJD: number, jd: number): number => {
    const v = Math.floor((jd - fromJD) * 1440);
    return v === 1440 ? v - 1 : v;
};

const SolarObjectsContextProvider: React.FC<Props> = ({
    children,
    defaultGeoLocation: { latitude, longitude, altitude },
}) => {
    const sun = new SSO(0, "Sun", 696300, createSunBodyMath, null);
    const earth = new SSO(3, "Earth", 6378.14, createEarthBodyMath, sun);
    const moon = new SSO(31, "Moon", 1737, createMoonBodyMath, earth);
    const n = new PlanetNavigator(earth, { latitude, longitude, altitude });

    const avgAngle = (a: number, b: number): number => {
        if (a > b) {
            const t = a;
            a = b;
            b = t;
        }
        if (b - a > Math.PI) b -= 2 * Math.PI;
        return (a + b) / 2;
    };

    const getAngle = (jd: number, meridianTime: number | null): number => {
        if (meridianTime) return moon.angle(meridianTime, n);
        const prev = moon.getMeridianTime(jd - 1, n) || jd - 0.5;
        const next = moon.getMeridianTime(jd + 1, n) || jd + 1.5;
        return avgAngle(moon.angle(prev, n), moon.angle(next, n));
    };

    const getPositions = (t: number): SolarObjectsPositions => {
        const jd = timestampToJd(t);
        let meridianTime = moon.getMeridianTime(jd, n);

        const angle = getAngle(jd, meridianTime);

        if (meridianTime === null) {
            meridianTime = jd + 0.5;
        }
        return {
            moon: moon.getPosition(meridianTime, n),
            earth: earth.getPosition(meridianTime, n),
            sun: sun.getPosition(meridianTime, n),
            angle,
            moonRotation: moon.localRotation(meridianTime),
        };
    };

    const getAppearances = (t: number): MoonAppearance[] => {
        const jd = timestampToJd(t);
        const culminationTime = moon.getCulminationTime(jd, n);
        const _appearances = moon.getMoonRiseSetTimes(jd, n);

        const appearances: MoonAppearance[] = _appearances.map(a => ({
            time: mins(jd, a.jd),
            kind: a.kind === "set" ? "setTime" : "riseTime",
        }));
        appearances.push({ time: mins(jd, culminationTime), kind: "culmTime" });
        appearances.sort((a, b) => a.time - b.time);

        return appearances;
    };

    const getParams = (t: number): MoonParams => {
        const jd = timestampToJd(t);
        const meridianTime = moon.getMeridianTime(jd, n) || jd + 0.5;

        return {
            age: MoonObjectCalculator.Age(meridianTime),
            illumination: moon.illumination(sun, meridianTime, n) * 100,
            angularSize: moon.angularSize(meridianTime, n) * Rad2Deg,
            magnitude: moon.apparentMagnitude(sun, meridianTime, n),
        };
    };

    const getPhase = (t: number): MoonPhase => {
        const jd = timestampToJd(t);
        return MoonObjectCalculator.Phase(jd);
    };

    return (
        <SolarObjectsContext.Provider
            value={{ getPositions, getPhase, getParams, getAppearances, n }}
        >
            {children}
        </SolarObjectsContext.Provider>
    );
};

export default SolarObjectsContextProvider;
