import { useContext, useEffect, useRef } from "react";
import {
    calendarSquareCell,
    cellFrame,
    dayNumber,
    filledCalendarCell,
    moonPhaseIconContainer,
} from "./CalendarCell.css.js";
import { ScreenshotContext } from "./contexts/RenderContextProvider.js";
import {
    DateSetterContext,
    PickedDateContext,
    PickedMonthContext,
} from "./contexts/DateContextProvider.js";
import { SolarObjectsContext } from "./contexts/SolarObjectsContextProvider.js";
import Icon from "../svg/Icon.js";
import { IconsMap } from "../svg/icons-map.js";
import { GeoLocationContext } from "./contexts/GeoLocationContextProvider.js";
import { MoonCalendarContentContext } from "./MoonCalendar.js";
import { TimezoneContext } from "./contexts/TimezoneContextProvider.js";
import { InitialRenderRefContext } from "../GlobalContextsProvider.js";
import { makeSsrImageFileName } from "./utils.js";
import { ASSETS_PATH } from "../../config/content.js";
// import { downloadScreenshot, makeSsrImageFileName } from "./utils.js";

interface Props {
    day: number;
    date: number;
}

const CalendarCell: React.FC<Props> = ({ day, date }) => {
    const isInitRender = useContext(InitialRenderRefContext);
    const { pickedGeoLocation } = useContext(GeoLocationContext);
    const { getTZOffset } = useContext(TimezoneContext);
    const dispatch = useContext(DateSetterContext);
    const { addCreateScreenshotTask } = useContext(ScreenshotContext);
    const cellRef = useRef<HTMLDivElement>(null);
    const pickedMonth = useContext(PickedMonthContext);

    const _date = date - getTZOffset(date);

    useEffect(() => {
        if (!cellRef.current) return;
        addCreateScreenshotTask(cellRef.current, _date, pickedMonth);
    }, [_date, pickedGeoLocation]);

    return (
        <div
            ref={cellRef}
            className={filledCalendarCell}
            style={{
                backgroundImage: isInitRender.current
                    ? `url(${ASSETS_PATH}/moon-calendar/phases/${makeSsrImageFileName(
                          date,
                          "c",
                      )})`
                    : undefined,
            }}
            onClick={() => {
                // const u = cellRef.current!.style.backgroundImage.slice(5, -4);
                // const fileName = makeSsrImageFileName(date, "c");
                // downloadScreenshot(u, fileName);
                dispatch({ type: "pick_date", date });
            }}
        >
            <div className={calendarSquareCell}>
                <CellFrame date={date}>
                    <MoonPhaseIcon date={_date} />
                    <span className={dayNumber}>{day}</span>
                </CellFrame>
            </div>
        </div>
    );
};

interface CellFrameProps {
    date: number;
    children: React.ReactNode;
}

const CellFrame: React.FC<CellFrameProps> = ({ date, children }) => {
    const { pickedDate, todayDate } = useContext(PickedDateContext);
    return (
        <div
            className={cellFrame}
            style={{
                boxShadow:
                    date === pickedDate
                        ? "0px 0px 8px 4px #484e97 inset" // TODO: move color to theme
                        : undefined,
                border: date === todayDate ? "2px solid #989fC5" : undefined, // TODO: move color to theme
                margin: date === todayDate ? "-1px" : undefined,
                zIndex: 2,
            }}
        >
            {children}
        </div>
    );
};

const phaseIconMap: Record<string, keyof IconsMap> = {
    Full: "fullMoon",
    New: "newMoon",
    FirstQuarter: "firstQuarterMoon",
    LastQuarter: "lastQuarterMoon",
};

interface MoonPhaseIconProps {
    date: number;
}

const MoonPhaseIcon: React.FC<MoonPhaseIconProps> = ({ date }) => {
    const { getPhase } = useContext(SolarObjectsContext);
    const moonCalendarContent = useContext(MoonCalendarContentContext);
    const phase = getPhase(date);
    const icon = phaseIconMap[phase];

    if (icon && phase !== "None") {
        return (
            <div
                className={moonPhaseIconContainer}
                title={moonCalendarContent[`name${phase}`]}
            >
                <Icon k={icon} />
            </div>
        );
    }

    return null;
};

export default CalendarCell;
