import gql from "graphql-tag";

// TODO: update fragments when parametrized fragments would be supported

export const thumbnail = gql`
    fragment thumbnail on MediaFile {
        fileName
        width
        height
        length
        contentType
    }
`;

export const imagePreview = gql`
    fragment imagePreview on Image {
        id
        content(langCode: $langCode) {
            name
            sourceUrl
            thumbnails {
                ...thumbnail
            }
        }
    }
    ${thumbnail}
`;

export const image = gql`
    fragment image on Image {
        id
        content(langCode: $langCode) {
            name
            description
            sourceUrl
            copyrights {
                author
                url
            }
            downloadUrl
            thumbnails {
                ...thumbnail
            }
        }
    }
    ${thumbnail}
`;

export const mediaItemPreview = gql`
    fragment mediaItemPreview on MediaItem {
        id
        content(langCode: $langCode) {
            name
            sourceUrl
            ... on ImageContent {
                thumbnails {
                    ...thumbnail
                }
            }
            ... on YoutubeVideoContent {
                aspectRatio
                thumbnailUrls
            }
        }
    }
    ${thumbnail}
`;

export const mediaItem = gql`
    fragment mediaItem on MediaItem {
        id
        content(langCode: $langCode) {
            name
            description
            sourceUrl
            copyrights {
                author
                url
            }
            ... on ImageContent {
                downloadUrl
                thumbnails {
                    ...thumbnail
                }
            }
            ... on YoutubeVideoContent {
                aspectRatio
                thumbnailUrls
            }
        }
    }
    ${thumbnail}
`;
export const quizBannerInfo = gql`
    fragment quizBannerInfo on Quiz {
        content(langCode: $langCode) {
            text
            metaDescription
            startButton
            mediaItem {
                ...mediaItem
            }
        }
    }
    ${mediaItem}
`;
export const infographicsBannerInfo = gql`
    fragment infographicsBannerInfo on Infographics {
        content(langCode: $langCode) {
            preview
        }
        previewImage {
            ...image
        }
    }
    ${image}
`;
