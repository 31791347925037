import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { BRAND_NAME } from "../../../_common/content.js";
import SwitchPageTracker from "../common/SwitchPageTracker.js";

interface Props {
    value?: string;
}

const HtmlTitle: React.FC<Props> = ({ value }) => {
    const { pathname, search } = useLocation();
    return (
        <>
            <SwitchPageTracker
                path={pathname + search}
                title={value ? `${value} | ${BRAND_NAME}` : BRAND_NAME}
            />
            <Helmet
                defaultTitle={BRAND_NAME}
                titleTemplate={`%s | ${BRAND_NAME}`}
            >
                {value && <title>{value}</title>}
            </Helmet>
        </>
    );
};

export default HtmlTitle;
