import type { LangCode } from "./types.js";

export const langCodes: LangCode[] = [
    "en",
    "de",
    "es",
    "fr",
    "it",
    "pt",
    "nl",
    "ru",
    "ja",
    "ko",
    "zh-Hans",
    "zh-Hant",
];
