import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Content from "../../i18n/content.js";
import { gaCookieConsent } from "../../helpers/googleAnalytics.js";
import { button, contentBlock, textBlock } from "./CookieConsent.css.js";
import { CookieConsentServiceContext } from "../GlobalContextsProvider.js";

const CookieConsent: React.FC = () => {
    const cookieConsentService = useContext(CookieConsentServiceContext);
    const [isVisible, setIsVisible] = useState(false);
    const { pathname } = useLocation();
    const {
        langCode,
        common: { cookieText, cookieLrnMore, cookieAcptBtn },
    } = useContext(Content);

    useEffect(() => {
        if (cookieConsentService.shouldShowCookieConsent()) {
            setIsVisible(true);
        }
    }, []);

    if (isVisible) {
        const onClickHandler = () => {
            cookieConsentService.acceptCookie();
            setIsVisible(false);
            gaCookieConsent("accept", langCode, pathname);
        };

        return (
            <div className={contentBlock}>
                <div className={textBlock}>
                    {`${cookieText} `}
                    <Link
                        to={`/${langCode}/privacy-policy`}
                        onClick={() => {
                            gaCookieConsent("learn_more", langCode, pathname);
                        }}
                    >
                        {cookieLrnMore}
                    </Link>
                </div>
                <button className={button} onClick={onClickHandler}>
                    {cookieAcptBtn}
                </button>
            </div>
        );
    }

    return null;
};

export default CookieConsent;
