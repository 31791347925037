import React, { useContext } from "react";
import Content from "../../i18n/content.js";

interface Props {
    v: string | number;
    className?: string;
    onlyMonth?: boolean;
    onClick?: () => void;
}

const DateFormatter: React.FC<Props> = ({
    v,
    className,
    onlyMonth,
    onClick,
}) => {
    const { langCode } = useContext(Content);

    const d = new Date(v);

    const formattedDate = d.toLocaleString(langCode, {
        month: onlyMonth ? "long" : "short",
        day: onlyMonth ? undefined : "numeric",
        year: "numeric",
        timeZone: "UTC",
    });

    const dt = d.toISOString().slice(0, onlyMonth ? 7 : 10);

    return (
        <time
            className={className}
            dateTime={dt}
            suppressHydrationWarning
            onClick={onClick}
        >
            {formattedDate}
        </time>
    );
};

export default DateFormatter;
