import { HOUR } from "../../../_common/constants.js";
import { GeoLocationOption } from "../../graphql/queries/moon-calendar.js";
import { CityGeoLocation } from "./contexts/GeoLocationContextProvider.js";

export const makeSsrImageFileName = (t: number, k: "c" | "m") =>
    `moon-phase-london-uk-${new Date(t).toISOString().slice(0, 10)}-${k}.png`;

export const formatTZOffset = (tzOffset: number): string => {
    const sign = tzOffset < 0 ? "-" : "+";
    const hoursDecimal = Math.abs(tzOffset / HOUR);
    const minutesDecimal = hoursDecimal - Math.floor(hoursDecimal);
    return `UTC${sign}${Math.floor(hoursDecimal)}${
        minutesDecimal ? `:${minutesDecimal * 60}` : ""
    }`;
};

export const isTimezoneSupported = (tzName: string): boolean =>
    Intl.supportedValuesOf("timeZone").includes(tzName);

export const getCurrentTimezoneName = () =>
    Intl.DateTimeFormat().resolvedOptions().timeZone;

export const geoLocationOptionToCityGeoLocation = (
    g: GeoLocationOption,
): CityGeoLocation => ({
    id: g.id,
    langs: g.contentLangs,
    lang: g.content.langCode,
    type: "City",
    name: g.content.name,
    country: g.content.country || undefined,
    latitude: g.latitude,
    longitude: g.longitude,
    altitude: g.altitude,
    timezone: g.timezone,
});

// export const downloadScreenshot = async (data: string, fileName: string) => {
//     try {
//         const res = await fetch(data);
//         const buf = await res.arrayBuffer();
//         const file = new File([buf], fileName, { type: "image/png" });
//         const url = URL.createObjectURL(file);
//         const link = document.createElement("a");
//         link.href = url;
//         link.download = fileName;
//         link.click();
//     } catch (e) {
//         console.error(e);
//     }
// };
