import React from "react";
import Markdown from "react-markdown";
import { Image } from "../../../graphql/queries/gallery.js";
import ImageItem, { SrcSetSizes } from "./ImageItem.js";
import { figure } from "./ImageWithCaption.css.js";
import MediaItemCopyrights from "./MediaItemCopyrights.js";

interface Props {
    image: Image;
    srcSetSizes: SrcSetSizes;
}

const ImageWithCaption: React.FC<Props> = ({ image, srcSetSizes }) => {
    const description = image.content.description ? (
        <Markdown
            children={image.content.description}
            unwrapDisallowed
            disallowedElements={["p"]}
        />
    ) : null;

    return (
        <figure className={figure}>
            <ImageItem
                noAspectWrapper
                image={image}
                srcSetSizes={srcSetSizes}
            />
            <figcaption>
                {description}
                <MediaItemCopyrights copyrights={image.content.copyrights} />
            </figcaption>
        </figure>
    );
};

export default ImageWithCaption;
