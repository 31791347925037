import React from "react";
import type { ExperimentsFeatures } from "../components/GlobalContextsProvider.js";
import { Image } from "../graphql/queries/gallery.js";

export interface ConfigData {
    conversionDomains: string[];
    newsFeedPageItemsCount: number;
    searchFeedPageItemsCount: number;
    recommendedNewsCount: number;
    googleAnalytics4Id?: string;
    experimentsFeatures?: ExperimentsFeatures;
    facebookPixelId?: string;
    twitterUsername?: string;
    adSensePubId?: string;
    vapidPubKey?: string;
    imageExperimentsMap: ImageExperimentsMap;
}

export interface ImageABTestConfig {
    imageId: string;
    experimentKey: string;
    altImages: (Image | null)[];
}

interface ActiveImageABTestConfig extends ImageABTestConfig {
    altImages: Image[];
}

const ConfigContext = React.createContext<ConfigData>(undefined!);

type ImageExperimentsMap = Record<string, ActiveImageABTestConfig>;

export const buildConfig = (
    data?: Partial<ConfigData> | null,
    imageTests?: ImageABTestConfig[],
): ConfigData => {
    const imageExperimentsMap: ImageExperimentsMap = {};

    if (imageTests) {
        for (const imageTest of imageTests) {
            if (imageTest.altImages.includes(null)) continue;
            imageExperimentsMap[imageTest.imageId] = {
                ...imageTest,
                altImages: imageTest.altImages.filter((i): i is Image => !!i),
            };
        }
    }

    return {
        conversionDomains: [],
        newsFeedPageItemsCount: 20,
        searchFeedPageItemsCount: 15,
        recommendedNewsCount: 4,
        imageExperimentsMap,
        ...data,
    };
};

export default ConfigContext;
