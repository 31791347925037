export const skyObjects = [
    {
        id: "ursa",
        name: "Ursa Major",
        category: "the big bear, the big dipper",
        angle: 4.2,
        image: `UMa.png`,
    },
    {
        id: "sir",
        name: "Sirius",
        category: "HIP 32349, HR 2491, HD 48915",
        angle: 27.1,
        setTime: "16:23",
        riseTime: "00:57",
        image: `Star_B.png`,
    },
    {
        id: "ven",
        name: "Venus",
        category: "2nd planet",
        angle: 10,
        setTime: "01:26",
        riseTime: "17:19",
        image: `Ven.png`,
    },
    {
        id: "c2019",
        name: "C/2019 U6 (Lemmon)",
        category: "comet",
        angle: 30.1,
        setTime: "8:23",
        riseTime: "00:57",
        image: `Com.png`,
    },
    {
        id: "iss",
        name: "ISS",
        category: "NORAD ID 25544",
        angle: 12.7,
        setTime: "19:37",
        riseTime: "21:11",
        image: `25544.png`,
    },
    {
        id: "ori",
        name: "Orion",
        category: "the hunter",
        angle: 28.8,
        image: `Ori.png`,
    },
    {
        id: "stl",
        name: "STARLINK-1329",
        category: "NORAD ID 45531",
        angle: 45.4,
        setTime: "17:33",
        riseTime: "19:05",
        image: `Starlink.png`,
    },
];
