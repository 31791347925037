import React, { useCallback, useContext, useState } from "react";
import debounce from "lodash.debounce";

import {
    SEARCH_GEO_LOCATION,
    SearchLocationsData,
    SearchLocationsVariables,
} from "../../../graphql/queries/moon-calendar.js";
import { CityGeoLocation } from "../contexts/GeoLocationContextProvider.js";
import { geoLocationOptionToCityGeoLocation } from "../utils.js";
import Content from "../../../i18n/content.js";
import { useLazyQuery } from "@apollo/client/react/hooks/useLazyQuery.js";
import {
    searchInput,
    inputContainer,
    loaderContainer,
    mainInput,
    searchResultsItem,
    searchResultsList,
    cityName,
    countryName,
} from "./CitySearcher.css.js";
import CircleSpinner2 from "../../common/CircleSpinner2.js";
import { formRow } from "../../styles/common.css.js";
import { labelStyle } from "./styles.css.js";
import Icon from "../../svg/Icon.js";

interface Props {
    value: string;
    onChange: (geoLocation: CityGeoLocation) => void;
    label: string;
    placeholder: string;
}
const CitySearcher: React.FC<Props> = ({
    value,
    onChange,
    label,
    placeholder,
}) => {
    return (
        <div className={formRow} style={{ position: "relative" }}>
            <label className={labelStyle}>{label}</label>
            <div className={inputContainer}>
                <CitySearchInput
                    placeholder={placeholder}
                    onChange={onChange}
                />
                <input className={mainInput} value={value} disabled />
            </div>
        </div>
    );
};

interface CitySearchInputProps {
    placeholder: string;
    onChange: (geoLocation: CityGeoLocation) => void;
}

const CitySearchInput: React.FC<CitySearchInputProps> = ({
    onChange,
    placeholder,
}) => {
    const { langCode } = useContext(Content);
    const [searchValue, setSearchValue] = useState<string>("");

    const [search, { data, loading }] = useLazyQuery<
        SearchLocationsData,
        SearchLocationsVariables
    >(SEARCH_GEO_LOCATION);

    const deboucedSearch = useCallback(debounce(search, 500), []);

    const key = searchValue.trim();

    if (key.length > 2) {
        deboucedSearch({
            variables: {
                key,
                langCode: langCode,
            },
        });
    }

    return (
        <>
            <input
                className={searchInput}
                value={searchValue}
                onChange={e => setSearchValue(e.currentTarget.value)}
                placeholder={placeholder}
            />
            <span className={loaderContainer}>
                {loading ? <CircleSpinner2 /> : <Icon k="search" />}
            </span>
            {key.length > 2 ? (
                <div className={searchResultsList}>
                    {(data?.searchGeoLocations?.nodes || []).map(g => (
                        <div
                            className={searchResultsItem}
                            key={g.id}
                            onMouseDown={() => {
                                onChange(geoLocationOptionToCityGeoLocation(g));
                                setSearchValue("");
                            }}
                        >
                            <div className={cityName}>{g.content.name}</div>
                            {g.content.country && (
                                <div className={countryName}>
                                    {g.content.country}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    );
};

export default CitySearcher;
