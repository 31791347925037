import React from "react";
import { flex } from "../styles/common.css.js";
import { getIconProps, IconKey } from "./icons-map.js";

interface Props {
    k: IconKey;
    className?: string;
    style?: React.CSSProperties;
}

const Icon: React.FC<Props> = ({ k, className, style }) => {
    const { viewBox, path } = getIconProps(k);

    const p =
        typeof path === "string" ? (
            <path d={path} />
        ) : Array.isArray(path) ? (
            path.map((_p, i) => <path key={`p${i}`} {..._p} />)
        ) : (
            <path {...path} />
        );

    const classNames = [flex];
    if (className) classNames.push(className);

    return (
        <span className={classNames.join(" ")} style={style}>
            <svg
                viewBox={viewBox}
                fill="currentColor"
                height="1em"
                fillRule="evenodd"
            >
                {p}
            </svg>
        </span>
    );
};

export default Icon;
