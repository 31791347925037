import React, { useEffect, useContext } from "react";
import { Waypoint } from "react-waypoint";

import {
    PromoAppSetterContext,
    GetButtonVisibilityContext,
} from "./PromoAppContextProvider.js";
import AbsolutePoint, { PointPosition } from "../common/AbsolutePoint.js";

interface Props {
    position?: PointPosition;
    children?: React.ReactNode;
}

const ActionsTrigger: React.FC<Props> = ({ children, position }) => {
    const pickPromoApp = useContext(PromoAppSetterContext);
    const isVisible = useContext(GetButtonVisibilityContext);
    const onPositionChange = ({ currentPosition }: Waypoint.CallbackArgs) => {
        if (currentPosition === "above" && !isVisible) {
            pickPromoApp({ type: "show" });
        }
        if (currentPosition === "inside") {
            pickPromoApp({ type: "hide" });
        }
    };

    useEffect(() => {
        return () => {
            pickPromoApp({ type: "hide" });
        };
    }, [pickPromoApp]);

    if ((!!position && !!children) || (!position && !children)) {
        throw new Error(
            "ActionsTrigger: either position or children prop must be passed, not both.",
        );
    }

    return (
        <Waypoint onPositionChange={onPositionChange}>
            {children ? children : <AbsolutePoint position={position!} />}
        </Waypoint>
    );
};

export default ActionsTrigger;
