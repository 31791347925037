import React from "react";
import {
    IMediaItem,
    IMediaItemPreview,
} from "../../../graphql/queries/gallery.js";
import ImageItem, { SrcSetSizes } from "./ImageItem.js";
import ImageLoader from "./ImageLoader.js";
import MediaItemCopyrights from "./MediaItemCopyrights.js";
import VideoFrame from "./VideoFrame.js";

interface Props {
    mediaItem: IMediaItem | IMediaItemPreview | null;
    previewIfVideo?: boolean;
    showPlaceholder?: boolean;
    srcSetSizes: SrcSetSizes;
    withCopyrights?: boolean;
}

const MediaItem: React.FC<Props> = ({
    mediaItem,
    previewIfVideo,
    showPlaceholder,
    srcSetSizes,
    withCopyrights,
}) => {
    if (!mediaItem) return null;
    if (mediaItem.__typename === "Image") {
        return (
            <>
                <ImageItem
                    image={mediaItem}
                    srcSetSizes={srcSetSizes}
                    showPlaceholder={showPlaceholder}
                />
                {withCopyrights && mediaItem.content.copyrights && (
                    <MediaItemCopyrights
                        copyrights={mediaItem.content.copyrights}
                    />
                )}
            </>
        );
    }
    if (mediaItem.__typename === "YoutubeVideo") {
        const { sourceUrl, aspectRatio, thumbnailUrls, name, copyrights } =
            mediaItem.content;
        if (previewIfVideo)
            return (
                <>
                    <ImageLoader
                        aspectRatio={aspectRatio}
                        src={thumbnailUrls[0]}
                        alt={name || undefined}
                    />
                    {withCopyrights && mediaItem.content.copyrights && (
                        <MediaItemCopyrights copyrights={copyrights} />
                    )}
                </>
            );
        return (
            <>
                <VideoFrame
                    aspectRatio={aspectRatio}
                    src={sourceUrl}
                    title={name || ""}
                />
                {withCopyrights && mediaItem.content.copyrights && (
                    <MediaItemCopyrights copyrights={copyrights} />
                )}
            </>
        );
    }
    console.error(`MediaItem type not supported`);
    return null;
};

export default MediaItem;
