import React from "react";
import { PromoItem } from "../../../graphql/queries/_common.js";
import InfographicsBanner from "./InfographicsBanner.js";
import QuizBanner from "./QuizBanner.js";

interface Props {
    promoItem: PromoItem | undefined;
}
const PromoBanner: React.FC<Props> = ({ promoItem }) => {
    if (!promoItem) return null;
    if (promoItem.__typename === "Quiz") {
        return (
            <div>
                <QuizBanner item={promoItem} />
            </div>
        );
    }
    if (promoItem.__typename === "Infographics") {
        return (
            <div>
                <InfographicsBanner item={promoItem} />
            </div>
        );
    }
    return <div />;
};
export default PromoBanner;
