import React, { useContext } from "react";

import { container } from "../../styles/common.css.js";
import { defaultLinkRel } from "../../../config/content.js";
import { Partner } from "../../../graphql/queries/about.js";
import {
    imageLink,
    imagesContainer,
    logo,
    section,
    sectionTitle,
} from "./PartnersSection.css.js";
import { gaExternalLinkClick } from "../../../helpers/googleAnalytics.js";
import Content from "../../../i18n/content.js";

interface Props {
    title: string;
    partners: Partner[];
}

const PartnersSection: React.FC<Props> = ({ title, partners }) => {
    const { langCode } = useContext(Content);
    return (
        <section className={section}>
            <div className={container.flex}>
                <h2 className={sectionTitle}>{title}</h2>
                <div className={imagesContainer}>
                    {partners.map(
                        ({ url, imageUrl, content: { name }, rel }) => (
                            <a
                                className={imageLink}
                                key={url}
                                href={url}
                                rel={rel || defaultLinkRel}
                                onClick={() =>
                                    gaExternalLinkClick(
                                        url,
                                        langCode,
                                        "/",
                                        "partners_section",
                                    )
                                }
                            >
                                <img
                                    className={logo}
                                    src={imageUrl}
                                    alt={name}
                                />
                            </a>
                        ),
                    )}
                </div>
            </div>
        </section>
    );
};

export default PartnersSection;
