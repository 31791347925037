import React from "react";
import { Helmet } from "react-helmet";
import { BRAND_NAME } from "../../../_common/content.js";
import { ASSETS_PATH } from "../../config/content.js";

const HtmlAppleIcons: React.FC = () => (
    <Helmet>
        <meta name="apple-mobile-web-app-title" content={BRAND_NAME} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
            name="apple-mobile-web-app-status-bar-style"
            content="black-translucent"
        />
        {[
            {
                file: "touch-icon-iphone.png",
                size: "120x120",
            },
            {
                file: "touch-icon-ipad.png",
                size: "152x152",
            },
            {
                file: "touch-icon-iphone-retina.png",
                size: "180x180",
            },
            {
                file: "touch-icon-ipad-retina.png",
                size: "167x167",
            },
        ].map(({ file, size }) => (
            <link
                key={file}
                rel="apple-touch-icon"
                href={`${ASSETS_PATH}/icons/${file}`}
                sizes={size}
            />
        ))}
    </Helmet>
);

export default HtmlAppleIcons;
