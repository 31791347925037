import React from "react";
import { Link } from "react-router-dom";
import {
    cardContainer,
    cardDescription,
    cardInfo,
    cardTitle,
    mediaItemContainerStyleVariants,
    titleLink,
} from "./Card.css.js";
import { IMediaItemPreview } from "../../graphql/queries/gallery.js";
import ArticleInfoBlock from "./ArticleInfoBlock.js";
import MediaItem from "../gallery/_common/MediaItem.js";
import { SrcSetSizes } from "../gallery/_common/ImageItem.js";
import { Publication } from "../../graphql/queries/search.js";
import { LangCode } from "../../../_common/types.js";
import { gaFeedItemClick } from "../../helpers/googleAnalytics.js";

export interface CardContentProps {
    title: string;
    date: string;
    description?: string;
    mediaItem?: IMediaItemPreview;
    link: string;
    readingTime?: number | null;
    onClickHandler?: () => void;
}

export const makeCardContentProps = (
    item: Publication,
    langCode: LangCode,
    path: string,
): CardContentProps => {
    switch (item.__typename) {
        case "News":
            return {
                title: item.content.title,
                mediaItem: item.image,
                link: `/${langCode}/news/${item.id}`,
                description: item.content.preview,
                readingTime: item.content.readingTime,
                date: item.date,
                onClickHandler: () => {
                    gaFeedItemClick(`/news/${item.id}`, langCode, path);
                },
            };
        case "Quiz":
            return {
                title: item.content.title,
                mediaItem: item.content.mediaItem,
                link: `/${langCode}/quiz/${item.id}`,
                description: item.content.text,
                date: item.date,
                onClickHandler: () => {
                    gaFeedItemClick(`/quiz/${item.id}`, langCode, path);
                },
            };
        case "Infographics":
            return {
                title: item.content.title,
                mediaItem: item.previewImage,
                link: `/${langCode}/infographics/${item.id}`,
                description: item.content.preview,
                date: item.date,
                onClickHandler: () => {
                    gaFeedItemClick(`/infographics/${item.id}`, langCode, path);
                },
            };
        case "Tutorial":
            return {
                title: item.content.title,
                link: `/${langCode}/tutorials/${item.id}`,
                description: item.content.metaDescription,
                readingTime: item.content.readingTime,
                date: item.date,
                onClickHandler: () => {
                    gaFeedItemClick(`/tutorials/${item.id}`, langCode, path);
                },
            };
    }
};

interface Props {
    srcSetSizes: SrcSetSizes;
    size?: "large" | "small";
    levelHeading?: "h3";
    children?: React.ReactNode;
}

const Card: React.FC<Props & CardContentProps> = ({
    link,
    date,
    mediaItem,
    srcSetSizes,
    title,
    description,
    onClickHandler,
    children,
    readingTime,
    levelHeading,
    size = "small",
}) => {
    return (
        <div className={cardContainer}>
            {mediaItem && (
                <div className={mediaItemContainerStyleVariants[size]}>
                    <MediaItem
                        mediaItem={mediaItem}
                        srcSetSizes={srcSetSizes}
                        previewIfVideo
                    />
                </div>
            )}
            <Link className={titleLink} to={link} onClick={onClickHandler}>
                {levelHeading === "h3" ? (
                    <h3 className={cardTitle}>{title}</h3>
                ) : (
                    <h2 className={cardTitle}>{title}</h2>
                )}
            </Link>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <ArticleInfoBlock
                    className={cardInfo}
                    date={date}
                    readingTime={readingTime}
                />
                {children}
            </div>

            {description && <p className={cardDescription}>{description}</p>}
        </div>
    );
};

export default Card;
