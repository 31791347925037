import React, { useContext } from "react";
import { gaExternalLinkClick } from "../../helpers/googleAnalytics.js";
import Content from "../../i18n/content.js";
import { useLocation } from "react-router";

const ProductHuntLink: React.FC = () => {
    const { langCode } = useContext(Content);
    const { pathname } = useLocation();
    const to = "https://www.producthunt.com/posts/star-walk-2-free";
    return (
        <div style={{ textAlign: "center", margin: "0.75rem" }}>
            <a
                href={
                    to +
                    "?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-star&#0045;walk&#0045;2&#0045;free"
                }
                target="_blank"
                rel="noopener"
                onClick={() => gaExternalLinkClick(to, langCode, pathname)}
            >
                <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=391565&theme=light"
                    alt="Star&#0032;Walk&#0032;2&#0032;Free - The&#0032;ultimate&#0032;stargazing&#0032;companion&#0058;&#0032;your&#0032;portable&#0032;planetarium | Product Hunt"
                    style={{ width: "218px", height: "47px" }}
                />
            </a>
        </div>
    );
};

export default ProductHuntLink;
