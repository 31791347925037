import gql from "graphql-tag";
import { Copyright, EngagementInfo, FeedConnection } from "./_common.js";
import { IImagePreview, Image, MediaItemBase } from "./gallery.js";
import { image, imagePreview } from "./fragments.js";
import type { LangCode } from "../../../_common/types.js";
import { Topic } from "./topics.js";
import { App } from "./apps.js";

export const infographicsPreview = gql`
    fragment infographicsPreview on Infographics {
        id
        date
        content(langCode: $langCode) {
            title
            preview
        }
        previewImage {
            ...image
        }
    }
`;
export interface InfographicsContent {
    title: string;
    metaTitle: string | null;
    preview: string;
    description: string | null;
    keywords: string | null;
}
export interface Infographics<C = InfographicsContent> {
    id: string;
    __typename: "Infographics";
    slug: string | null;
    date: string;
    contentLangs: LangCode[];
    content: C;
    copyrights: Copyright[];
    infographicImage: Image;
    previewImage: IImagePreview;
    engagementInfo: EngagementInfo;
    featuredInfographics: InfographicsForFeed[] | null;
    topics: (Topic | null)[];
    promoApps: (App | null)[];
}

export type InfographicsForFeed = Pick<
    Infographics<Pick<InfographicsContent, "title" | "preview">>,
    "id" | "__typename" | "date" | "content" | "previewImage"
>;

export interface InfographicsForBanner
    extends MediaItemBase<
        "Infographics",
        Pick<InfographicsContent, "preview">
    > {
    previewImage: IImagePreview;
}

export interface GetInfographicsData {
    infographics: Infographics;
}

export interface GetInfographicsVariables {
    id: string;
    langCode?: LangCode;
}

export const GET_INFOGRAPHICS = gql`
    query getInfographics($langCode: LangCode, $id: InfographicsID!) {
        infographics(id: $id) {
            __typename
            id
            slug
            date
            contentLangs
            content(langCode: $langCode) {
                langCode
                title
                metaTitle
                preview
                description
                keywords
            }
            copyrights {
                author
                url
            }
            previewImage {
                ...imagePreview
            }
            infographicImage {
                ...image
            }
            engagementInfo {
                facebook
                messenger
                twitter
                telegram
                whatsapp
                native
            }
            featuredInfographics {
                id
                date
                content(langCode: $langCode) {
                    title
                    preview
                }
                previewImage {
                    ...imagePreview
                }
            }
            topics {
                id
                content(langCode: $langCode) {
                    title
                }
            }
            promoApps(platforms: [ios, android, huawei]) {
                id
                title
                icon {
                    sourceUrl
                    sizes
                }
                marketUrl
                marketId
                platform
            }
        }
    }
    ${imagePreview}
    ${image}
`;
export interface GetInfographicsForFeedVariables {
    langCode?: LangCode;
    first?: number;
    after?: string;
}
export interface GetInfographicsFeedData {
    infographicsFeed: FeedConnection<InfographicsForFeed> | null;
}
export const GET_INFOGRAPHICS_FEED = gql`
    query getInfographicsFeed(
        $langCode: LangCode
        $first: Int
        $after: InfographicsCursor
    ) {
        infographicsFeed(first: $first, after: $after) {
            totalCount
            offset
            edges {
                cursor
                node {
                    id
                    date
                    content(langCode: $langCode) {
                        title
                        preview
                    }
                    previewImage {
                        ...imagePreview
                    }
                }
            }
        }
    }
    ${imagePreview}
`;
