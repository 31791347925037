import React, { useEffect } from "react";
import {  useLocation } from "react-router";

// Scroll restoration based on https://reacttraining.com/react-router/web/guides/scroll-restoration.
const ScrollToTop: React.FC = () => {

    const { pathname, hash } = useLocation();

    const isInitialRenderingWithHash = !!hash && hash !=="#0";

    useEffect(() => {
        if (!isInitialRenderingWithHash) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
            });
        }
    }, [pathname, isInitialRenderingWithHash]);

    return null;
};

export default ScrollToTop;
