import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { container } from "../../styles/common.css.js";
import Content from "../../../i18n/content.js";
import Card from "../../common/Card.js";
import { QuizForFeed } from "../../../graphql/queries/games.js";
import { gaTrackQuizEvent } from "../../../helpers/googleAnalytics.js";
import ScrollTracker from "../../common/ScrollTracker.js";
import SummaryBadge from "../../games/quiz/SummaryBadge.js";
import {
    quizSectionContent,
    section,
    sectionButton,
} from "./QuizSection.css.js";
import {
    commonSectionText,
    commonSectionTitle,
    titlesContainer,
} from "./homeCommon.css.js";

interface Props {
    title: string;
    text: string;
    button: string;
    quiz: QuizForFeed;
}

const QuizSection: React.FC<Props> = ({ title, text, button, quiz }) => {
    const { langCode } = useContext(Content);
    return (
        <section className={section}>
            <div className={container.flex}>
                <div className={quizSectionContent}>
                    <ScrollTracker
                        position="50%"
                        eventName="quiz_section_visible"
                    />
                    <div className={titlesContainer}>
                        <h2 className={commonSectionTitle}>{title}</h2>
                        <p className={commonSectionText}>{text}</p>
                        <Link
                            className={sectionButton}
                            to={`/${langCode}/quiz/${quiz.id}#0`}
                            onClick={() => {
                                gaTrackQuizEvent(
                                    "Start",
                                    quiz.id,
                                    langCode,
                                    undefined,
                                    "/",
                                    "button",
                                );
                            }}
                        >
                            {button}
                        </Link>
                    </div>
                    <Card
                        title={quiz.content.title}
                        date={quiz.date}
                        mediaItem={quiz.content.mediaItem}
                        srcSetSizes={{
                            largePhone: 200,
                        }}
                        link={`/${langCode}/quiz/${quiz.id}#0`}
                        onClickHandler={() => {
                            gaTrackQuizEvent(
                                "Start",
                                quiz.id,
                                langCode,
                                undefined,
                                "/",
                                "card",
                            );
                        }}
                        description={quiz.content.metaDescription}
                    >
                        <SummaryBadge
                            questionsNumber={quiz.questionsNumber}
                            avgScore={quiz.avgScore}
                            usersCount={quiz.usersCount}
                        />
                    </Card>
                </div>
            </div>
        </section>
    );
};

export default QuizSection;
