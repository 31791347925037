import { createContext, useMemo, useState } from "react";
import { DAY_HALF, MIN } from "../../../../_common/constants.js";

export type Timezone = number | string;

export const TimezoneContext = createContext<{
    timezone: Timezone;
    getTZOffset: (t: number) => number;
    setTimezone: (timezone: Timezone) => void;
}>({
    timezone: "Current",
    getTZOffset: () => 0,
    setTimezone: () => {},
});

interface Props {
    children: React.ReactNode;
    defaultTimezone: string;
}

const getTZOffsetByTimezoneName = (timeZone: string, t: number) => {
    const d = new Date(t);
    const tz = Date.parse(d.toLocaleString("en-US", { timeZone }));
    return tz - t - d.getTimezoneOffset() * MIN;
};

const TimezoneContextProvider: React.FC<Props> = ({
    children,
    defaultTimezone,
}) => {
    const [timezone, setTimezone] = useState<Timezone>(defaultTimezone);
    const cache = useMemo(() => new Map<string, number>(), []);

    const getTZOffset = (t: number): number => {
        if (typeof timezone === "number") return timezone;
        t += DAY_HALF;
        const cacheKey = timezone + ":" + t;
        const cached = cache.get(cacheKey);
        if (cached) return cached;
        const value =
            timezone === "Current"
                ? new Date(t).getTimezoneOffset() * MIN * -1
                : getTZOffsetByTimezoneName(timezone, t);
        cache.set(cacheKey, value);
        return value;
    };

    return (
        <TimezoneContext.Provider
            value={{ timezone, setTimezone, getTZOffset }}
        >
            {children}
        </TimezoneContext.Provider>
    );
};

export default TimezoneContextProvider;
