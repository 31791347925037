import React from "react";
import GoogleAdSenseAdUnit from "../../common/ads/GoogleAdSenseAdUnit.js";
import { QuizStage } from "./QuizContainer.js";

interface Props {
    id: string;
    stage: QuizStage;
    questionsCount: number;
}

const makeQuizBannerKey = (
    stage: QuizStage,
    questionsCount: number,
): number => {
    if (stage === "start") {
        return 1;
    } else if (stage === "result") {
        return 4;
    } else if (Math.floor((stage / questionsCount) * 100) < 30) {
        return 1;
    } else if (Math.floor((stage / questionsCount) * 100) < 60) {
        return 2;
    } else {
        return 3;
    }
};

const QuizPageAdBanner: React.FC<Props> = ({ id, stage, questionsCount }) => {
    const key = `${id}${makeQuizBannerKey(stage, questionsCount)}`;
    return (
        <GoogleAdSenseAdUnit
            adSlot="9476758249"
            itemId={key}
            key={key}
            adFormat="fluid"
            fullWidthResponsive="true"
            style={{
                display: "block",
                width: "100%",
                margin: "1rem auto",
            }}
        />
    );
};

export default QuizPageAdBanner;
