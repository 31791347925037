import gql from "graphql-tag";
import { image } from "./fragments.js";
import { quizPreview } from "./games.js";
import { infographicsPreview } from "./infographics.js";
import { newsPreview } from "./news.js";
import { tutorialPreview } from "./tutorials.js";
import { Publication } from "./search.js";
import { FeedConnection } from "./_common.js";
import { LangCode } from "../../../_common/types.js";

export interface GetTopicVariables {
    id: string;
    langCode?: LangCode;
    first?: number;
    after?: string;
}
export interface Topic {
    id: string;
    content: {
        title: string;
        description: string | null;
        metaTitle: string;
        metaDescription: string | null;
    };
    items: FeedConnection<Publication> | null;
}
export interface TopicPreview {
    id: string;
    content: {
        title: string;
    };
}
export interface GetTopicContent {
    topic: Topic | null;
}
export const GET_TOPIC = gql`
    query getTopic(
        $id: TopicID!
        $langCode: LangCode
        $first: Int
        $after: TopicItemCursor
    ) {
        topic(id: $id) {
            id
            content(langCode: $langCode) {
                title
                description
                metaTitle
                metaDescription
            }
            items(first: $first, after: $after) {
                totalCount
                offset
                edges {
                    cursor
                    node {
                        ... on News {
                            ...newsPreview
                        }
                        ... on Infographics {
                            ...infographicsPreview
                        }
                        ... on Tutorial {
                            ...tutorialPreview
                        }
                        ... on Quiz {
                            ...quizPreview
                        }
                    }
                }
            }
        }
    }
    ${image}
    ${newsPreview}
    ${infographicsPreview}
    ${tutorialPreview}
    ${quizPreview}
`;
