import { Field } from "react-final-form";
import { formatTZOffset, getCurrentTimezoneName } from "../utils.js";
import { formRow } from "../../styles/common.css.js";
import { inputStyle, labelStyle } from "./styles.css.js";

interface Props {
    label: string;
}

const TimezoneSelect: React.FC<Props> = ({ label }) => (
    <Field<string | number>
        name="timezone"
        render={({ input: { onChange, value } }) => {
            return (
                <div className={formRow}>
                    <label htmlFor="timezone-select" className={labelStyle}>
                        {label}
                    </label>
                    <select
                        id="timezone-select"
                        className={inputStyle}
                        value={value}
                        onChange={e => {
                            const num = Number(e.currentTarget.value);
                            if (isNaN(num)) {
                                onChange(e.currentTarget.value);
                            } else {
                                onChange(num);
                            }
                        }}
                    >
                        <TimezoneSelectOptions />
                    </select>
                </div>
            );
        }}
    />
);

const TimezoneSelectOptions: React.FC = () => (
    <Field<(string | number)[]>
        name="timezoneOptions"
        render={({ input: { value } }) => {
            return (
                <>
                    {value.map(v => (
                        <option key={`${v}`} value={v}>
                            {typeof v === "number"
                                ? formatTZOffset(v)
                                : v === "Current"
                                ? getCurrentTimezoneName()
                                : v}
                        </option>
                    ))}
                </>
            );
        }}
    />
);

export default TimezoneSelect;
