import React from "react";
import Icon from "../svg/Icon.js";
import DateFormatter from "./DateFormatter.js";
import {
    infoBlock,
    readingTimeBlock,
    styleTimeRead,
} from "./ArticleInfoBlock.css.js";

interface Props {
    date: string;
    readingTime?: number | null;
    className?: string;
}

const ArticleInfoBlock: React.FC<Props> = ({
    date,
    readingTime,
    className,
}) => {
    return (
        <div
            className={className ? `${infoBlock} ${className}` : infoBlock}
            data-nosnippet
        >
            <DateFormatter v={date} />
            {readingTime && (
                <div className={readingTimeBlock}>
                    <Icon k="timer" />
                    <span className={styleTimeRead}>
                        {`~${readingTime} min`}
                    </span>
                </div>
            )}
        </div>
    );
};

export default ArticleInfoBlock;
