import React from "react";
import AspectRatio from "./AspectRatio.js";
import { frame } from "./VideoFrame.css.js";

interface Props {
    aspectRatio: string;
    showPlaceholder?: boolean;
    src: string;
    title: string;
}

const VideoFrame: React.FC<Props> = ({
    aspectRatio,
    showPlaceholder,
    src,
    title,
}) => {
    return (
        <AspectRatio v={aspectRatio} showPlaceholder={showPlaceholder}>
            <iframe
                className={frame}
                width="100%"
                height="100%"
                src={src}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                title={title}
            />
        </AspectRatio>
    );
};

export default VideoFrame;
