import React from "react";
import { abbrevieteNumber } from "../../../helpers/utils.js";
import Icon from "../../svg/Icon.js";
import {
    badgeItem,
    introSummaryBadgeContainer,
    summaryBadgeContainer,
    badgeValue,
} from "./SummaryBadge.css.js";

interface Props {
    questionsNumber: number;
    avgScore: number | null;
    usersCount: number | null;
    isIntro?: boolean;
}
const SummaryBadge: React.FC<Props> = ({
    questionsNumber,
    avgScore,
    usersCount,
    isIntro,
}) => {
    return (
        <>
            {usersCount && avgScore ? (
                <div
                    className={`${
                        isIntro
                            ? introSummaryBadgeContainer
                            : summaryBadgeContainer
                    } summary-wrapper`}
                    data-nosnippet
                >
                    <div className={badgeItem}>
                        <Icon k="users" />
                        <span className={badgeValue}>
                            {abbrevieteNumber(usersCount)}
                        </span>
                    </div>
                    <div className={badgeItem}>
                        <Icon k="trophy" />
                        <span className={badgeValue}>
                            {`${avgScore} / ${questionsNumber}`}
                        </span>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default SummaryBadge;
