import gql from "graphql-tag";
import { Copyright, EngagementInfo } from "./_common.js";
import { thumbnail } from "./fragments.js";
import { YoutubeVideo, Image } from "./gallery.js";
import type { LangCode } from "../../../_common/types.js";
import { Topic } from "./topics.js";

export const tutorialPreview = gql`
    fragment tutorialPreview on Tutorial {
        id
        date
        content(langCode: $langCode) {
            title
            readingTime
            metaDescription
        }
    }
`;
export interface Tutorial<C, M = undefined> {
    __typename: "Tutorial";
    id: string;
    slug: string | null;
    date: string;
    updatedAt: string;
    featured: boolean;
    contentLangs: LangCode[];
    content: C;
    copyright: Copyright;
    engagementInfo: EngagementInfo;
    mediaItem: M | null;
    topics: (Topic | null)[];
}

export interface TutorialContent {
    title: string;
    metaTitle: string;
    metaDescription: string;
    text: string;
    readingTime: number | null;
    keywords: string | null;
}

export interface GetTutorialVariables {
    langCode?: LangCode;
    id: string;
}

export interface GetTutorialData {
    tutorial: Tutorial<TutorialContent, YoutubeVideo> | null;
}

export const GET_TUTORIAL = gql`
    query getTutorial($langCode: LangCode, $id: TutorialID!) {
        tutorial(id: $id) {
            id
            slug
            date
            updatedAt
            featured
            contentLangs
            content(langCode: $langCode) {
                title
                metaTitle
                metaDescription
                text
                readingTime
                keywords
            }
            mediaItem {
                id
                ... on YoutubeVideo {
                    content(langCode: $langCode) {
                        thumbnailUrls
                        aspectRatio
                        sourceUrl
                        copyrights {
                            author
                            url
                        }
                        name
                    }
                }
            }
            copyright {
                author
                url
            }
            engagementInfo {
                facebook
                messenger
                twitter
                telegram
                whatsapp
                native
            }
            topics {
                id
                content(langCode: $langCode) {
                    title
                }
            }
        }
    }
`;

type TutorialContentForList = Pick<TutorialContent, "title">;

export type TutorialForList = Pick<
    Tutorial<TutorialContentForList>,
    "id" | "date" | "featured" | "content"
>;

export interface GetTutorialGroupsVariables {
    langCode: LangCode;
}
export interface TutorialGroup {
    id: string;
    content: {
        title: string;
    };
    image: Image | null;
    tutorials: (TutorialForList | null)[];
}
export interface GetTutorialGroupsData {
    tutorialGroups: TutorialGroup[] | null;
}
export const GET_TUTORIAL_GROUPS = gql`
    query tutorialGroups($langCode: LangCode) {
        tutorialGroups {
            id
            content(langCode: $langCode) {
                title
            }
            image {
                id
                content(langCode: $langCode) {
                    name
                    sourceUrl
                    thumbnails {
                        ...thumbnail
                    }
                }
            }
            tutorials {
                id
                content(langCode: $langCode) {
                    title
                }
            }
        }
    }
    ${thumbnail}
`;
