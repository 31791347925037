import React, { useEffect } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import { DocumentNode } from "graphql";
import {
    GetFeedForSitemapVariables,
    SitemapListData,
} from "../../graphql/queries/sitemap.js";
import type { LangCode } from "../../../_common/types.js";
import LinksList from "./LinksList.js";

interface Props {
    query: DocumentNode;
    langCode: LangCode;
    rootPath: string;
}

const first = 100;

const LinksListLoader: React.FC<Props> = ({ query, langCode, rootPath }) => {
    const { loading, data, fetchMore } = useQuery<
        SitemapListData,
        GetFeedForSitemapVariables
    >(query, {
        variables: {
            langCode,
            first: first,
        },
    });

    const handleLoadMore = (after: string) => {
        fetchMore({
            variables: {
                langCode,
                first: first,
                cursor: after,
            },
        });
    };

    const nextCursor =
        data &&
        data.feed.totalCount > data.feed.edges.length &&
        data.feed.edges.length > 0
            ? data.feed.edges[data.feed.edges.length - 1].cursor
            : null;

    useEffect(() => {
        if (nextCursor) {
            handleLoadMore(nextCursor);
        }
    }, [nextCursor]);

    return (
        <LinksList
            links={data?.feed.edges || []}
            rootPath={rootPath}
            loading={loading}
        />
    );
};

export default LinksListLoader;
