import React, { useEffect, useRef } from "react";
import { useApolloClient } from "@apollo/client/react/hooks/useApolloClient.js";
import { initPushSubscriptionApi } from "./push-notifications/init.js";
import { PushSubscriptionApi } from "./push-notifications/push-subscription-api.js";
import { GrowthBook } from "../helpers/growthbook/GrowthBook.js";
import type { FeatureDefinition } from "../helpers/growthbook/types/growthbook.js";
import { isDev } from "../config/env.js";
import { isClient } from "../helpers/utils.js";
import { CookieConsentService } from "./cookie-consent/cookie-consent-service.js";
import { trackGA4ExperimentStart } from "../helpers/googleAnalytics.js";

export type ExperimentsFeatures = Record<string, FeatureDefinition>;

export const getUUID = () => {
    const COOKIE_NAME = "gbuuid";
    const COOKIE_DAYS = 400; // 400 days is the max cookie duration for chrome

    // use the browsers crypto.randomUUID if set
    const genUUID = () => {
        if (window?.crypto?.randomUUID) return window.crypto.randomUUID();
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (
                +c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] &
                    (15 >> (+c / 4)))
            ).toString(16),
        );
    };
    const getCookie = (name: string) => {
        let value = `; ${document.cookie}`;
        let parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(";").shift();
    };
    const setCookie = (name: string, value: string) => {
        var d = new Date();
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * COOKIE_DAYS);
        document.cookie =
            name + "=" + value + ";path=/;expires=" + d.toUTCString();
    };

    // get the existing UUID from cookie if set, otherwise create one and store it in the cookie
    if (getCookie(COOKIE_NAME)) return getCookie(COOKIE_NAME);

    const uuid = genUUID();
    setCookie(COOKIE_NAME, uuid);
    return uuid;
};

export const PushSubscriptionApiContext =
    React.createContext<PushSubscriptionApi | null>(null);

export const InitialRenderRefContext = React.createContext<
    React.RefObject<boolean>
>({ current: true });

export const ExperimentsContext = React.createContext<GrowthBook | null>(null);

export const CookieConsentServiceContext =
    React.createContext<CookieConsentService>(new CookieConsentService());

interface Props {
    children: React.ReactNode;
}

const GlobalContextsProvider: React.FC<Props> = ({ children }) => {
    const initRenderRef = useRef(true);
    const client = useApolloClient();
    const state = initPushSubscriptionApi(client);
    const cookieConsentService = new CookieConsentService();
    let growthbook: GrowthBook | null = null;
    if (isClient()) {
        const gbuuid = getUUID();
        growthbook = new GrowthBook({
            enableDevMode: isDev,
            attributes: {
                id: gbuuid,
            },
            trackingCallback: (experiment, result) => {
                trackGA4ExperimentStart(
                    experiment.key,
                    result.variationId,
                    gbuuid,
                );
            },
        });
    }
    useEffect(() => {
        initRenderRef.current = false;
    }, []);
    return (
        <CookieConsentServiceContext.Provider value={cookieConsentService}>
            <PushSubscriptionApiContext.Provider value={state}>
                <InitialRenderRefContext.Provider value={initRenderRef}>
                    <ExperimentsContext.Provider value={growthbook}>
                        {children}
                    </ExperimentsContext.Provider>
                </InitialRenderRefContext.Provider>
            </PushSubscriptionApiContext.Provider>
        </CookieConsentServiceContext.Provider>
    );
};

export default GlobalContextsProvider;
