import React from "react";
import type { IconKey } from "../../svg/icons-map.js";
import Icon from "../../svg/Icon.js";
import { ASSETS_PATH } from "../../../config/content.js";
import { container } from "../../styles/common.css.js";
import ScrollTracker from "../../common/ScrollTracker.js";
import {
    icon,
    iconsContainer,
    image,
    imageContainer,
} from "./ExtensiveSection.css.js";
import {
    commonSection,
    commonSectionText,
    commonSectionTitle,
    sectionContent,
    titlesContainer,
} from "./homeCommon.css.js";

interface Props {
    title: string;
    text: string;
}

const iconKeys: IconKey[] = [
    "cube3D",
    "info",
    "compass",
    "structure",
    "map",
    "gallery",
    "wiki",
];

const ExtensiveSection: React.FC<Props> = ({ title, text }) => {
    return (
        <section className={commonSection}>
            <div className={container.flex}>
                <div className={sectionContent}>
                    <ScrollTracker
                        position="50%"
                        eventName="extensive_section_visible"
                    />
                    <div className={titlesContainer}>
                        <h2 className={commonSectionTitle}>{title}</h2>
                        <p className={commonSectionText}>{text}</p>
                    </div>
                    <div className={imageContainer}>
                        <div className={iconsContainer}>
                            {iconKeys.map(key => (
                                <Icon k={key} key={key} className={icon} />
                            ))}
                        </div>
                        <img
                            className={image}
                            src={`${ASSETS_PATH}/test/moon3.png`}
                            alt="Full moon"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExtensiveSection;
