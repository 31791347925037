import gql from "graphql-tag";
import type { SocialMedia } from "../../../_common/types.js";

export type NodeWithEngagementInfoTypename =
    | "News"
    | "Tutorial"
    | "Quiz"
    | "Image"
    | "Video"
    | "YoutubeVideo"
    | "Infographics";

export interface IncrementEngagementCounterVariables {
    input: {
        socialMedia: SocialMedia;
        typename: NodeWithEngagementInfoTypename;
        id: string;
    };
}

export const INCREMENT_ENGAGEMENT_COUNTER = gql`
    mutation incrementEngagementCounter(
        $input: IncrementEngagementCounterInput!
    ) {
        incrementEngagementCounter(input: $input) {
            node {
                id
            }
        }
    }
`;
