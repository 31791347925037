import React from "react";
import { Helmet } from "react-helmet";

const HtmlLargeImagePreview: React.FC = () => (
    <Helmet>
        <meta name="robots" content="max-image-preview:large" />
    </Helmet>
);

export default HtmlLargeImagePreview;
