import { createContext, useContext, useEffect, useRef } from "react";
import { PickedDateRefContext } from "./DateContextProvider.js";
import { SolarObjectsContext } from "./SolarObjectsContextProvider.js";

const initScenesModule = () => import("../scene.js");

interface Props {
    children: React.ReactNode;
}

class PromiseQueue {
    private queue = Promise.resolve();
    private currentQueueId: number = 0;

    add(operation: () => Promise<void>, queueId: number): Promise<void> {
        if (queueId !== this.currentQueueId) {
            this.queue = Promise.resolve();
            this.currentQueueId = queueId;
        }

        return new Promise((resolve, reject) => {
            this.queue = this.queue
                .then(() => {
                    if (this.currentQueueId !== queueId) {
                        throw new Error(`Queue aborted.`);
                    }
                    return operation();
                })
                .then(resolve)
                .catch(e => {
                    if (e.message === "Queue aborted.") {
                        resolve();
                    } else {
                        reject(e);
                    }
                });
        });
    }
}

export type ScreenshotMaker = (t: number) => Promise<string>;

export const CanvasRefsContext = createContext<{
    mainCanvasRef: React.RefObject<HTMLCanvasElement>;
    hiddenCanvasRef: React.RefObject<HTMLCanvasElement>;
    loaderRef: React.RefObject<HTMLDivElement>;
    defaultImgRef: React.RefObject<HTMLImageElement>;
}>({
    mainCanvasRef: { current: null },
    hiddenCanvasRef: { current: null },
    loaderRef: { current: null },
    defaultImgRef: { current: null },
});

export const ScreenshotContext = createContext<{
    addCreateScreenshotTask: (
        cell: HTMLDivElement,
        t: number,
        queueId: number,
    ) => void;
}>({
    addCreateScreenshotTask: () => {},
});

const RenderContextProvider: React.FC<Props> = ({ children }) => {
    const mainCanvasRef = useRef<HTMLCanvasElement>(null);
    const hiddenCanvasRef = useRef<HTMLCanvasElement>(null);
    const loaderRef = useRef<HTMLDivElement>(null);
    const defaultImgRef = useRef<HTMLImageElement>(null);

    const { getPositions, n } = useContext(SolarObjectsContext);
    const pickedDateRef = useContext(PickedDateRefContext);

    const queue = new PromiseQueue();

    let screenshotMakerReadyResolver: (fn: ScreenshotMaker) => void;

    const screenshotMaker: Promise<ScreenshotMaker> = new Promise(resolve => {
        screenshotMakerReadyResolver = resolve;
    });

    const createScreenshot = async (cell: HTMLDivElement, date: number) => {
        try {
            const makeScreenshot = await screenshotMaker;
            const data = await makeScreenshot(date);
            cell.style.backgroundImage = `url(${data})`;
        } catch (e) {
            console.error(e);
        }
    };

    const addCreateScreenshotTask = (
        cell: HTMLDivElement,
        date: number,
        queueId: number,
    ) => {
        queue.add(() => createScreenshot(cell, date), queueId);
    };

    useEffect(() => {
        if (loaderRef.current) {
            loaderRef.current.style.display = "flex";
        }

        let onDestroy: () => void;
        const init = async () => {
            const initScenes = await initScenesModule();

            onDestroy = await initScenes.default(
                mainCanvasRef,
                hiddenCanvasRef,
                pickedDateRef,
                n,
                getPositions,
                screenshotMakerReadyResolver,
            );
            if (defaultImgRef.current) {
                defaultImgRef.current.style.display = "none";
            }
            if (loaderRef.current) {
                loaderRef.current.style.display = "none";
            }
        };

        init();

        return () => {
            if (onDestroy) onDestroy();
        };
    }, [mainCanvasRef, hiddenCanvasRef, loaderRef, defaultImgRef]);

    return (
        <CanvasRefsContext.Provider
            value={{ mainCanvasRef, hiddenCanvasRef, loaderRef, defaultImgRef }}
        >
            <ScreenshotContext.Provider
                value={{
                    addCreateScreenshotTask,
                }}
            >
                {children}
            </ScreenshotContext.Provider>
        </CanvasRefsContext.Provider>
    );
};

export default RenderContextProvider;
