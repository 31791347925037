import React, { useContext, useRef } from "react";
import Menu from "./Menu.js";
import { header, hidden, navContainer } from "./Header.css.js";
import { gaMobileMenu } from "../../helpers/googleAnalytics.js";
import Content from "../../i18n/content.js";
import { useLocation } from "react-router";

const unFreezeBackground = () => {
    if (
        typeof window !== "undefined" &&
        typeof document !== "undefined" &&
        document.body.style
    ) {
        const scrollY = parseInt(document.body.style.top || "0");
        document.body.removeAttribute("style");
        window.scrollTo(0, scrollY * -1);
    }
};

const freezeBackground = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
        document.body.setAttribute(
            "style",
            `position: fixed; top: -${window.scrollY}px`,
        );
    }
};

const Header: React.FC = () => {
    const menuStateRef = useRef<HTMLInputElement>(null);
    const langSelectRef = useRef<HTMLInputElement>(null);
    const { langCode } = useContext(Content);
    const { pathname } = useLocation();

    const closeMenu = () => {
        if (menuStateRef.current) {
            menuStateRef.current.checked = false;
        }
        if (langSelectRef.current) {
            langSelectRef.current.checked = false;
        }
        unFreezeBackground();
    };
    const toggleMenu = () => {
        if (menuStateRef.current) {
            const isOpen = menuStateRef.current.checked;
            gaMobileMenu(isOpen ? "close" : "open", langCode, pathname);
            if (!isOpen) {
                freezeBackground();
            } else {
                unFreezeBackground();
            }
        }
    };
    return (
        <>
            <input
                className={hidden}
                id="menu-state"
                type="checkbox"
                ref={menuStateRef}
            />
            <input
                className={hidden}
                id="lang-picker-state"
                type="checkbox"
                ref={langSelectRef}
            />
            <header className={header}>
                <div className={navContainer}>
                    <Menu closeMenu={closeMenu} toggleMenu={toggleMenu} />
                </div>
            </header>
        </>
    );
};

export default Header;
