import React, { useContext, useEffect, useMemo, useState } from "react";
import Markdown from "react-markdown";
import MarkdownLink from "./MarkdownLink";
import {
    ExperimentsContext,
    InitialRenderRefContext,
} from "../GlobalContextsProvider";

interface Props {
    original: React.ReactElement;
    alternativeSources: string[];
    featureKey: string;
}

const MarkdownParagraphTest: React.FC<Props> = ({
    original,
    alternativeSources,
    featureKey,
}) => {
    const initRender = useContext(InitialRenderRefContext);
    const gb = useContext(ExperimentsContext);
    const variant = useMemo(() => gb?.getFeatureValue(featureKey, 0) || 0, []);
    const [pickedVariant, pickVariant] = useState<number>(
        initRender.current ? 0 : variant,
    );

    useEffect(() => {
        if (pickedVariant !== variant) {
            pickVariant(variant);
        }
    }, []);

    if (pickedVariant > 0) {
        return (
            <Markdown
                skipHtml
                children={alternativeSources[pickedVariant - 1]}
                components={{
                    a: MarkdownLink,
                }}
            />
        );
    }

    // if (variant === 0 && pickedVariant === 0) {
    //     return (
    //         <OnceVisibilityTracker featureKey={featureKey}>
    //             {original}
    //         </OnceVisibilityTracker>
    //     );
    // }

    return original;
};

export default MarkdownParagraphTest;
