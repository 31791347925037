import React from "react";
import { Helmet } from "react-helmet";
import { isDev } from "../../config/env.js";

const HtmlHotJar: React.FC = () => {
    if (isDev) return null;
    const snippet = `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2835941,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

    return (
        <Helmet>
            <script>{snippet}</script>
        </Helmet>
    );
};

export default HtmlHotJar;
