import React, { MutableRefObject, useContext, useReducer, useRef } from "react";
import { DAY } from "../../../../_common/constants.js";
import { TimezoneContext } from "./TimezoneContextProvider.js";
import { isClient } from "../../../helpers/utils.js";

interface PickedDateState {
    pickedDate: number;
    pickedMonth: number;
    todayDate: number;
}

type PickDateAction =
    | { type: "next_day" }
    | { type: "prev_day" }
    | { type: "next_month" }
    | { type: "prev_month" }
    | { type: "set_today" }
    | { type: "pick_date"; date: number };

type DateSetter = (
    prevValue: PickedDateState,
    action: PickDateAction,
) => PickedDateState;

type Dispatch = (action: PickDateAction) => void;

export const DateSetterContext = React.createContext<Dispatch>(() => {});
export const PickedDateRefContext = React.createContext<
    MutableRefObject<number>
>({ current: 0 });
export const PickedDateContext = React.createContext<{
    pickedDate: number;
    todayDate: number;
    _pickedDate: number;
}>({ pickedDate: 0, todayDate: 0, _pickedDate: 0 });
export const PickedMonthContext = React.createContext<number>(0);

const dateSetter: DateSetter = (state, action) => {
    switch (action.type) {
        case "next_day": {
            const pickedDate = state.pickedDate + DAY;
            return {
                ...state,
                pickedDate,
                pickedMonth: getMonthStart(pickedDate),
            };
        }
        case "prev_day": {
            const pickedDate = state.pickedDate - DAY;
            return {
                ...state,
                pickedDate,
                pickedMonth: getMonthStart(pickedDate),
            };
        }
        case "next_month": {
            const m = new Date(state.pickedMonth);
            m.setUTCMonth(m.getUTCMonth() + 1);
            return {
                ...state,
                pickedMonth: m.getTime(),
            };
        }
        case "prev_month": {
            const m = new Date(state.pickedMonth);
            m.setUTCMonth(m.getUTCMonth() - 1);
            return {
                ...state,
                pickedMonth: m.getTime(),
            };
        }
        case "pick_date": {
            const pickedDate = action.date;
            return {
                ...state,
                pickedDate,
                pickedMonth: getMonthStart(pickedDate),
            };
        }
        case "set_today": {
            const todayDate = getCurrentDayStart();
            return {
                ...state,
                pickedDate: todayDate,
                pickedMonth: getMonthStart(todayDate),
                todayDate,
            };
        }
        default:
            throw new Error("Invalid action type in dateSetter");
    }
};

interface Props {
    children: React.ReactNode;
}

const getCurrentDayStart = (): number => {
    const d = new Date();
    if (!isClient()) {
        return Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
    }
    return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
};

const getMonthStart = (t: number) => {
    const d = new Date(t);
    d.setUTCDate(1);
    return d.getTime();
};

const DateContextProvider: React.FC<Props> = ({ children }) => {
    const [{ pickedDate, pickedMonth, todayDate }, setDate] = useReducer<
        DateSetter,
        PickedDateState
    >(
        dateSetter,
        {
            pickedDate: 0,
            pickedMonth: 0,
            todayDate: 0,
        },
        () => {
            const initialDate = getCurrentDayStart();
            return {
                pickedDate: initialDate,
                pickedMonth: getMonthStart(initialDate),
                todayDate: initialDate,
            };
        },
    );
    const { getTZOffset } = useContext(TimezoneContext);
    const tzOffset = getTZOffset(pickedDate);
    const _pickedDate = pickedDate - tzOffset;
    const pickedDateRef = useRef<number>(_pickedDate);
    pickedDateRef.current = _pickedDate;

    // console.log({ tzOffset: (tzOffset / DAY) * 24, u: !(pickedDate % DAY) });

    return (
        <DateSetterContext.Provider value={setDate}>
            <PickedDateContext.Provider
                value={{ pickedDate, todayDate, _pickedDate }}
            >
                <PickedMonthContext.Provider value={pickedMonth}>
                    <PickedDateRefContext.Provider value={pickedDateRef}>
                        {children}
                    </PickedDateRefContext.Provider>
                </PickedMonthContext.Provider>
            </PickedDateContext.Provider>
        </DateSetterContext.Provider>
    );
};

export default DateContextProvider;
