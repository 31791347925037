import gql from "graphql-tag";

export interface DepartmentSelect {
    Support: string;
    Marketing: string;
    Team: string;
}
export interface PlatformSelect {
    ios: string;
    android: string;
}
export interface AppVersionSelect {
    starwalk2: string;
    "starwalk2-free": string;
}
export interface ContactsPageContent {
    title: string;
    contactBtn: string;
    metaTitle: string;
    metaDescription: string;
    locationTitle: string;
    _contactsText: string;
    _addressOne: string;
    _addressTwo: string;

    formTitle: string;
    nameInputLbl: string;
    nameInputPlcholder: string;
    emailInputLbl: string;
    emailInputPlaceholder: string;
    messageInputLbl: string;
    errMsgText: string;
    inputReqText: string;
    emailInvalidText: string;
    captchaInvalidText: string;
    submitBtn: string;
    popupMsgText: string;
    okBtn: string;
    captchaTitle: string;
    departmentSelectLbl: string;
    departmentSupport: string;
    departmentMarketing: string;
    departmentDevTeam: string;
    platformSelectLbl: string;
    versionSelectLbl: string;
}

export interface GetContactsData {
    staticContent: {
        id: string;
        content: ContactsPageContent;
    };
}

export const GET_CONTACTS = gql`
    query getContacs($langCode: LangCode) {
        staticContent(id: "sw-site-contacts") {
            id
            content(langCode: $langCode)
        }
    }
`;

export type AppPlatform =
    | "ios"
    | "android"
    | "huawei"
    | "wsa"
    | "web"
    | "editor";

export type Department = "Support" | "Marketing" | "Team";
export interface SendMessageInput {
    to: Department;
    source: string;
    productInfo?: {
        productId?: string;
        platform?: AppPlatform;
        appId?: string;
    };
    email: string;
    name: string;
    message: string;
}

export interface SendMessagePayload {
    ok: boolean;
    message: string;
}

export const CREATE_MESSAGE = gql`
    mutation sendMessage($input: SendMessageInput!) {
        sendMessage(input: $input) {
            ok
            message
        }
    }
`;
