import React, { useContext } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";

import HtmlLang from "../helmet/HtmlLang.js";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { LangCode } from "../../../_common/types.js";
import PromoAppContextProvider from "./PromoAppContextProvider.js";
import Content, { buildContent } from "../../i18n/content.js";
import ConfigContext, { buildConfig } from "../../config/config-context.js";
import CookieConsent from "../cookie-consent/CookieConsent.js";
import {
    GetCommonContentData,
    GET_COMMON_CONTENT,
    GetCommonContentVariables,
} from "../../graphql/queries/common-content.js";
import Spinner from "../Spinner.js";
import RootErrorMsg from "../common/errors/RootErrorMsg.js";
import ButtonActions from "./ButtonActions.js";
import { mainContent, screenBottomActions } from "./Layout.css.js";
import Head from "./Head.js";
import { ExperimentsContext } from "../GlobalContextsProvider.js";
import PushSubscriptionManager from "../push-notifications/PushSubscriptionManager.js";
// import Snowfall from "../common/Snowfall.js";

interface Props {
    children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
    const { lang } = useParams<{ lang: LangCode }>();
    const growthbook = useContext(ExperimentsContext);

    const { data, loading, error } = useQuery<
        GetCommonContentData,
        GetCommonContentVariables
    >(GET_COMMON_CONTENT, {
        variables: {
            langCode: (lang as LangCode) || "en",
        },
    });

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <RootErrorMsg />;
    }

    if (data) {
        if (data.app?.config?.experimentsFeatures && growthbook) {
            growthbook.setFeatures(data.app?.config?.experimentsFeatures);
        }
        return (
            <Content.Provider
                value={buildContent((lang as LangCode) || "en", data)}
            >
                <ConfigContext.Provider
                    value={buildConfig(
                        data.app?.config,
                        data.app?.abTestsConfigs || undefined,
                    )}
                >
                    <PromoAppContextProvider>
                        <HtmlLang lang={lang as LangCode} />
                        {/* <Snowfall /> */}
                        <Head />
                        <PushSubscriptionManager />
                        <Header />
                        <div className={mainContent}>{children}</div>
                        <Footer />
                        <div className={screenBottomActions} data-nosnippet>
                            <ButtonActions />
                            <CookieConsent />
                        </div>
                    </PromoAppContextProvider>
                </ConfigContext.Provider>
            </Content.Provider>
        );
    }

    return <RootErrorMsg />;
};

export default Layout;
