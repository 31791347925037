import React from "react";
import Logo from "../../svg/Logo.js";
import { aspect, logo, placeholder } from "./AspectRatio.css.js";

interface Props {
    v: string;
    showPlaceholder?: boolean;
    children?: React.ReactNode;
    innerRef?: React.LegacyRef<HTMLDivElement>;
}

const AspectRatio: React.FC<Props> = ({
    v,
    showPlaceholder = true,
    children,
    innerRef,
}) => {
    const [width, height] = v.split(":").map(size => Number(size));
    const padding = ((height / width) * 100).toFixed(2);

    return (
        <div
            className={aspect}
            style={{ paddingBottom: `${padding}%` }}
            ref={innerRef}
        >
            {showPlaceholder && (
                <div className={placeholder}>
                    <Logo className={logo} />
                </div>
            )}
            {children}
        </div>
    );
};

export default AspectRatio;
