import React, { useContext } from "react";
import Content from "../../i18n/content.js";
import { cardLabel } from "./CardLabel.css.js";
import { MenuItem } from "../layout/menu-config.js";

interface Props {
    typename: "News" | "Infographics" | "Quiz" | "Tutorial";
}

const labelNameMap: Record<string, MenuItem> = {
    News: "news",
    Tutorial: "tutorials",
    Infographics: "infographics",
    Quiz: "games",
};

const CardLabel: React.FC<Props> = ({ typename }) => {
    const { common } = useContext(Content);

    return <div className={cardLabel}>{common[labelNameMap[typename]]}</div>;
};

export default CardLabel;
