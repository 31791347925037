import React from "react";
import { NewsCustomBanner } from "../../../graphql/queries/news";
import CustomBanner from "./CustomBanner";

interface Props {
    name: string;
    customBanners?: NewsCustomBanner[] | null;
}

const CustomAdUnit: React.FC<Props> = ({ name, customBanners }) => {
    if (!customBanners) return null;

    const pickedBanner = customBanners.find(b => b.siteAdUnit === name);

    if (!pickedBanner) return null;

    return <CustomBanner {...pickedBanner} />;
};

export default CustomAdUnit;
