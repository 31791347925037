import React from "react";

import type { LangCode } from "../../_common/types.js";
import { GetCommonContentData } from "../graphql/queries/common-content.js";
import { App } from "../graphql/queries/apps.js";
import { IImagePreview } from "../graphql/queries/gallery.js";
import { langCodes } from "../../_common/lang-codes.js";

export interface LocalizedContent {
    languages: { [key: string]: string };
    langCode: LangCode;
    langCodes: LangCode[];
    promoApps: App[];
    appleAppMarketId?: string;
    common: CommonContent;
    newsFeedPage: NewsFeedPageContent;
    newsFeedMediaItems: (IImagePreview | null)[] | null;
    newsPage: NewsPageContent;
    tutorials: TutorialsListPageContent;
    tutorialsMediaItems: (IImagePreview | null)[] | null;
    gamesList: GamesListPageContent;
    gamesListMediaItems: (IImagePreview | null)[] | null;
    infographicsFeed: InfographicsFeedPageContent;
    infographicsFeedMediaItems: (IImagePreview | null)[] | null;
    infographicPage: InfographicPageContent;
    searchPage: SearchPageContent;
    quizPage: QuizPageContent;
}

export interface CommonContent {
    logo: string;

    home: string;
    search: string;
    news: string;
    tutorials: string;
    games: string;
    infographics: string;
    moon: string;
    pickLang: string;
    followUs: string;

    storeBadgeIos: string;
    storeBadgeAndroid: string;
    storeBadgeHuawei: string;

    ftrTerms: string;
    ftrPrivacy: string;
    ftrContact: string;
    ftrPressKit: string;
    ftrFollowUs: string;
    ftrAbout: string;
    ftrSitemap: string;

    errDefTitle: string;
    errDefMsg: string;
    errNetTitle: string;
    errNetMsg: string;
    notFoundTitle: string;
    notFoundMsg: string;

    cookieText: string;
    cookieLrnMore: string;
    cookieAcptBtn: string;

    pSubsAlrtText: string;
    pSubsAcptBtn: string;
    pSubsDeclBtn: string;
    pSubsPermErr: string;

    getAppBtn: string;
    loadMoreBtnGenrl: string;

    relatedTopics: string;

    eSubsEmailInp: string;
    eSubsFNameInp: string;
    eSubsLNameInp: string;
    eSubsSubscribeBtn: string;
    eSubsOkBtn: string;
    eSubsReqMsg: string;
    eSubsEmailInvalid: string;
    eSubsHeader: string;
    eSubsErrMsg: string;
}

export interface NewsFeedPageContent {
    title: string;
    description?: string;
    metaTitle: string;
    metaDescription: string;
    promoBannerDescription: string;
    keywords?: string;
}

export interface NewsPageContent {
    textCredit: string;
    infographicsBannerButton: string;
    featuredNewsListTitle: string;
}

export interface TutorialsListPageContent {
    title: string;
    metaTitle: string;
    metaDescription: string;
    _text: string;
    keywords?: string;
}

export interface GamesListPageContent {
    title: string;
    description?: string;
    metaTitle: string;
    metaDescription: string;
    keywords?: string;
}
export interface InfographicsFeedPageContent {
    title: string;
    description?: string;
    metaTitle: string;
    metaDescription: string;
    keywords?: string;
}
export interface InfographicPageContent {
    downloadInfographicBtnText: string;
    featuredInfographicsTitle: string;
}

export interface PartnersSectionContent {
    title: string;
}

export interface StoreBadgesAlts {
    ios: string;
    android: string;
    huawei: string;
}

export interface SearchPageContent {
    metaTitle: string;
    metaDescription: string;
    noMoreMessage: string;
    noResultsMessage: string;
    title: string;
    inputPlaceholder: string;
}
export interface QuizPageContent {
    featuredQuizzesTitle: string;
}

export const buildContent = (
    langCode: LangCode,
    {
        langs,
        common,
        newsFeedPage,
        newsPage,
        tutorialsListPage,
        gamesListPage,
        infographicsFeedPage,
        infographicPage,
        searchPage,
        quizPage,
        app,
    }: GetCommonContentData,
): LocalizedContent => {
    const promoApps = app?.promoApps.filter((a): a is App => !!a) || [];

    const languages = langs.contents.reduce<{ [key: string]: string }>(
        (map, c) => {
            map[c.langCode] = c.value;
            return map;
        },
        {},
    );

    return {
        languages,
        langCode: langCode,
        langCodes,
        promoApps,
        appleAppMarketId: promoApps.find(a => a.platform === "ios")?.marketId,
        common: common.content,
        newsPage: newsPage.content,
        newsFeedPage: newsFeedPage.content,
        newsFeedMediaItems: newsFeedPage.mediaItems,
        tutorials: tutorialsListPage.content,
        tutorialsMediaItems: tutorialsListPage.mediaItems,
        gamesList: gamesListPage.content,
        gamesListMediaItems: gamesListPage.mediaItems,
        infographicsFeed: infographicsFeedPage.content,
        infographicsFeedMediaItems: infographicsFeedPage.mediaItems,
        infographicPage: infographicPage.content,
        searchPage: searchPage.content,
        quizPage: quizPage.content,
    };
};

const Content = React.createContext<LocalizedContent>(undefined!);

export default Content;
