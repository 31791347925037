import { useContext } from "react";
import {
    dateSwitcherContainer,
    pickedMonth,
    rotatedIcon,
    switchButton,
} from "./MonthSwitcher.css.js";
import {
    DateSetterContext,
    PickedMonthContext,
} from "./contexts/DateContextProvider.js";
import Icon from "../svg/Icon.js";
import DateFormatter from "../common/DateFormatter.js";

const MonthSwitcher: React.FC = () => {
    const dispatch = useContext(DateSetterContext);
    return (
        <div className={dateSwitcherContainer}>
            <button
                onClick={() => {
                    dispatch({ type: `prev_month` });
                }}
                className={switchButton}
            >
                <Icon k="chevronL" />
            </button>
            <PickedMonth />
            <button
                onClick={() => {
                    dispatch({ type: `next_month` });
                }}
                className={switchButton}
            >
                <Icon className={rotatedIcon} k="chevronL" />
            </button>
        </div>
    );
};

const PickedMonth: React.FC = () => {
    const month = useContext(PickedMonthContext);
    return <DateFormatter v={month} onlyMonth className={pickedMonth} />;
};

export default MonthSwitcher;
