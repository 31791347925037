import React, { useState } from "react";
import { Field, FieldRenderProps } from "react-final-form";
import { formRow } from "../../styles/common.css.js";
import { Validator, composeValidators } from "../../common/formValidators.js";
import { erroredInputStyle, inputStyle, labelStyle } from "./styles.css.js";
type Validators = Validator<string | undefined>[];

interface Props {
    name: string;
    label: string;
    placeholder?: string;
    validators?: Validators;
    geoLocationTypeReset?: () => void;
}

const NumField: React.FC<Props> = ({
    name,
    label,
    placeholder,
    validators = [],
    geoLocationTypeReset,
}) => (
    <Field
        name={name}
        render={props => {
            return (
                <NumInput
                    {...props}
                    name={name}
                    label={label}
                    placeholder={placeholder}
                    geoLocationTypeReset={geoLocationTypeReset}
                />
            );
        }}
        validate={composeValidators(...validators)}
    />
);

interface NumInputProps {
    name: string;
    label: string;
    placeholder?: string;
    geoLocationTypeReset?: () => void;
}

const NumInput: React.FC<
    FieldRenderProps<number | string | null, HTMLInputElement> & NumInputProps
> = ({ name, input, meta, label, placeholder, geoLocationTypeReset }) => {
    const [value, setValue] = useState<string>(input.value + "");

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if (geoLocationTypeReset) geoLocationTypeReset();
        setValue(val);
        if (val === null || val.trim() === "") {
            input.onChange(null);
        } else {
            const num = Number(val);
            input.onChange(isNaN(num) ? val : num);
        }
    };
    if (!meta.active && value !== input.value + "") {
        setValue(input.value + "");
    }
    return (
        <div className={formRow}>
            <label htmlFor={`${name}-input`} className={labelStyle}>
                {label}
            </label>
            <input
                id={`${name}-input`}
                className={meta.error ? erroredInputStyle : inputStyle}
                type="text"
                placeholder={placeholder}
                {...input}
                value={value}
                onChange={changeHandler}
            />
        </div>
    );
};

export default NumField;
