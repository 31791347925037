import React from "react";
import { Helmet } from "react-helmet";
import type { LangCode } from "../../../_common/types.js";

interface Props {
    lang: LangCode;
}

const HtmlLang: React.FC<Props> = ({ lang }) => (
    <Helmet
        htmlAttributes={{
            lang,
        }}
    />
);

export default HtmlLang;
