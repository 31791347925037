import React from "react";
import {
    ReactBaseProps,
    ReactMarkdownProps,
} from "react-markdown/src/ast-to-react";
import MarkdownParagraphTest from "./MarkdownParagraphTest";

const MarkdownParagraph: React.FC<
    ReactBaseProps & ReactMarkdownProps & { className?: string }
> = ({ children, className, node }) => {
    if (node.properties && typeof node.properties.featureKey === "string") {
        const { featureKey, alternatives } = node.properties;
        return (
            <MarkdownParagraphTest
                featureKey={featureKey}
                alternativeSources={alternatives as string[]}
                original={<p className={className}>{children}</p>}
            />
        );
    }
    return <p className={className}>{children}</p>;
};

export default MarkdownParagraph;
