import React from "react";
import { circleBorder, circleSpinner } from "./CircleSpinner.css.js";

const CircleSpinner2: React.FC = () => (
    <svg
        className={circleSpinner}
        viewBox="0 0 50 50"
        height="1em"
        fill="currentColor"
        fillRule="evenodd"
    >
        <circle
            className={circleBorder}
            cx="25"
            cy="25"
            r="22"
            strokeWidth="3"
            stroke="currentColor"
            fill="none"
        />
    </svg>
);

export default CircleSpinner2;
