import React from "react";
import {
    feedContainer,
    feedContainerGrid,
    flexFeedContainer,
} from "./FeedContainers.css.js";

interface Props {
    children: React.ReactNode;
}

export const FeedContainerGrid: React.FC<Props> = ({ children }) => (
    <div className={feedContainerGrid}>{children}</div>
);

export const FeedContainer: React.FC<Props> = ({ children }) => (
    <main className={feedContainer}>{children}</main>
);

export const FlexFeedContainer: React.FC<Props> = ({ children }) => (
    <main className={flexFeedContainer}>{children}</main>
);
