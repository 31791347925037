import React from "react";
import { appBannersContainer } from "./AppBannersContainer.css.js";

interface Props {
    children: React.ReactElement;
}

const AppBannersContainer: React.FC<Props> = ({ children }) => (
    <aside className={appBannersContainer}>{children}</aside>
);

export default AppBannersContainer;
