import React from "react";
import { Helmet } from "react-helmet";

interface Props {
    value: string;
}

const HtmlDescription: React.FC<Props> = ({ value }) => (
    <Helmet>
        <meta name="description" content={value} />
    </Helmet>
);

export default HtmlDescription;
