import React from "react";
import { Field } from "react-final-form";
import { fieldErrorMessage, dangerInput } from "./TextField.css.js";
import { composeValidators, Validator } from "../formValidators.js";
import { formRow, hideLabel, minimalInput } from "../../styles/common.css.js";
type Validators = Validator<string | undefined>[];

interface Props {
    name: string;
    type?: "text" | "email";
    label: string;
    placeholder: string;
    validators?: Validators;
    simpleValidation?: boolean;
}

const TextField: React.FC<Props> = ({
    name,
    type = "text",
    label,
    placeholder,
    validators = [],
    simpleValidation,
}) => {
    const inputClassname = [minimalInput];

    return (
        <Field
            name={name}
            render={({ input, meta, rest }) => {
                if (simpleValidation && meta.error && meta.touched) {
                    inputClassname.push(dangerInput);
                }
                return (
                    <div className={formRow}>
                        <label htmlFor={`${name}-input`} className={hideLabel}>
                            {label}
                        </label>
                        <input
                            id={`${name}-input`}
                            className={inputClassname.join(" ")}
                            type={type}
                            placeholder={placeholder}
                            {...input}
                            {...rest}
                        />
                        {!simpleValidation && meta.error && meta.touched && (
                            <span className={fieldErrorMessage}>
                                {meta.error}
                            </span>
                        )}
                    </div>
                );
            }}
            validate={composeValidators(...validators)}
        />
    );
};

export default TextField;
