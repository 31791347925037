import extend from "extend";
import {
    Root,
    List,
    ListItem,
    PhrasingContent,
    Heading,
    StaticPhrasingContent,
} from "mdast";

const createToC = (root: Root, ordered: boolean = false): List | null => {
    const headings: Heading[] = [];
    for (const child of root.children) {
        if (child.type === "heading") {
            const id = child.data?.id as string;
            if (id) {
                // NOTE: making a copy of heading (maybe it is better to make a deep copy)
                headings.push({
                    ...child,
                });
            }
        }
    }
    return headings.length ? transformHeadingsToList(headings, ordered) : null;
};

const transformHeadingsToList = (map: Heading[], ordered: boolean): List => {
    const list: List = {
        type: "list",
        ordered,
        spread: false,
        data: {
            hProperties: {
                className: "toc",
            },
        },
        children: [],
    };
    let minDepth = Infinity;
    let index = -1;

    // Find minimum depth.
    while (++index < map.length) {
        if (map[index].depth < minDepth) {
            minDepth = map[index].depth;
        }
    }

    // Normalize depth.
    index = -1;

    while (++index < map.length) {
        map[index].depth -= minDepth - 1;
    }

    // Add TOC to list.
    index = -1;

    while (++index < map.length) {
        insert(map[index], list, ordered);
    }

    return list;
};

function insert(
    entry: Heading,
    parent: List | ListItem,
    ordered: boolean,
): void {
    // Parent is a List
    if (parent.type === "list") {
        if (entry.depth === 1) {
            parent.children.push({
                type: "listItem",
                spread: false,
                children: [
                    {
                        type: "paragraph",
                        children: [
                            {
                                type: "link",
                                url: "#" + entry.data?.id,
                                children: all(entry.children),
                            },
                        ],
                    },
                ],
            });
        } else if (parent.children.length > 0) {
            insert(entry, parent.children[parent.children.length - 1], ordered);
        } else {
            const item: ListItem = {
                type: "listItem",
                spread: false,
                children: [],
            };
            parent.children.push(item);
            insert(entry, item, ordered);
        }
    } else {
        // Parent is a ListItem
        const tail =
            (parent.children &&
                parent.children.length &&
                parent.children[parent.children.length - 1]) ||
            undefined;
        if (
            tail &&
            tail.type === "list"
            // parent.children[parent.children.length - 1] &&
            // parent.children[parent.children.length - 1].type === "list"
        ) {
            entry.depth--;
            insert(entry, tail, ordered);
        } else {
            const item: List = {
                type: "list",
                ordered,
                spread: false,
                children: [],
            };
            parent.children.push(item);
            entry.depth--;
            insert(entry, item, ordered);
        }
    }
}

function all(children: PhrasingContent[]): StaticPhrasingContent[] {
    let result: StaticPhrasingContent[] = [];
    let index = -1;

    if (children) {
        while (++index < children.length) {
            result = result.concat(one(children[index]));
        }
    }

    return result;
}

// TODO: replace this old `extend` util with something modern & simple
function one(
    node: PhrasingContent,
): StaticPhrasingContent | StaticPhrasingContent[] {
    if (
        node.type === "link" ||
        node.type === "linkReference"
        // we do not use footnotes
        // node.type === "footnote"
        // node.type === "footnoteReference"
    ) {
        return all(node.children);
    }

    if ("children" in node) {
        const { children, position, ...copy } = node;
        return Object.assign(extend(true, {}, copy), {
            children: all(node.children as PhrasingContent[]),
        });
    }

    const { position, ...copy } = node;
    return extend(true, {}, copy);
}

export default createToC;
