import React from "react";
import { Helmet } from "react-helmet";

const HtmlNoIndex: React.FC = () => (
    <Helmet>
        <meta name="robots" content="noindex, follow" />
    </Helmet>
);

export default HtmlNoIndex;
