export const makeRequiredValidator =
    (errorMessage: string) => (value?: string | number | null) => {
        if (
            value === undefined ||
            value === null ||
            (typeof value === "string" && value === "")
        )
            return errorMessage;
        return undefined;
    };
const isEmailValid = (value: string) => {
    var regex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return regex.test(value);
};
export const makeEmailValidator = (errorMessage: string) => (value?: string) =>
    value && isEmailValid(value) ? undefined : errorMessage;
export const makeCaptchaValidator =
    (errorMessage: string) => (value: boolean) =>
        value === true ? undefined : errorMessage;

export type Validator<V> = (v: V) => string | undefined;
export const composeValidators =
    <V>(...validators: Validator<V>[]) =>
    (v: V) =>
        validators.reduce<string | undefined>(
            (error, validator) => error || validator(v),
            undefined,
        );

export const isNumber = <V>(value: V) => {
    if (value === undefined || value === null) return;
    if (isNaN(Number(value))) return "Must be a number";
    return;
};
export const isLatValid = <V>(v: V) => {
    if (typeof v === "number" && v >= -90 && v <= 90) return;
    return "Value must be in range from -90 to 90";
};

export const isLngValid = <V>(v: V) => {
    if (typeof v === "number" && v >= -180 && v <= 180) return;
    return "Value must be in range from -180 to 180";
};

export const isAltValid = <V>(v: V) => {
    if (typeof v === "number" && v >= 0 && v <= 10000) return;
    return "Value must be in range from 0 to 100000";
};
