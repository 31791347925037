import gql from "graphql-tag";
import { FeedConnection } from "./_common.js";
import { IImagePreview } from "./gallery.js";
import { imagePreview, thumbnail } from "./fragments.js";
import { QuizForFeed } from "./games.js";
import { Partner } from "./about.js";
import { News, NewsContent } from "./news.js";
import type { LangCode } from "../../../_common/types.js";

export interface GetHomeContentVariables {
    langCode?: LangCode;
    first: number;
    reviewsFirst: number;
}

export interface HomePageContent {
    title: string;
    metaDescription: string;
    keywords?: string;
    welcomeSecTitle: string;
    welcomeSecText: string;
    skyMapSecTitle: string;
    skyMapSecText: string;
    simpleSecTitle: string;
    simpleSecText: string;
    newsSecTitle: string;
    newsSecText: string;
    extensiveSecTitle: string;
    extensiveSecText: string;
    observingSecTitle: string;
    observingSecText: string;
    customizedSecTitle: string;
    customizedSecText: string;
    arSecTitle: string;
    arSecText: string;
    timeSecTitle: string;
    timeSecText: string;
    toolsSecTitle: string;
    toolsSecText: string;
    reviewSecTitle: string;
    partnersSecTitle: string;
    quizSecTitle: string;
    quizSecText: string;
    quizSecBtn: string;
}
// type ImageForFeed = Pick<Image, "id" | "content">;

type NewsContentForHome = Pick<NewsContent, "title">;

export type NewsItemForHome = Pick<
    News<NewsContentForHome, IImagePreview>,
    "id" | "content" | "date" | "featured" | "image"
>;
export interface Review {
    author: string;
    quote: string;
    url: string | null;
}
interface Product {
    content: {
        reviews: Review[] | null;
    };
}

export interface GetHomeContentData {
    homePage: {
        id: string;
        content: HomePageContent;
        promoItems: QuizForFeed[] | null;
    };
    newsFeed: Pick<FeedConnection<NewsItemForHome>, "edges"> | null;
    partners: Partner[] | null;
    product: Product | null;
}

export const GET_HOME_CONTENT = gql`
    query getHomeContent($langCode: LangCode, $first: Int, $reviewsFirst: Int) {
        homePage: staticContent(id: "sw-site-home") {
            id
            content(langCode: $langCode)
            promoItems {
                ... on Quiz {
                    id
                    date
                    usersCount
                    questionsNumber
                    avgScore
                    content(langCode: $langCode) {
                        title
                        text
                        metaDescription
                        mediaItem {
                            id
                            ... on Image {
                                content(langCode: $langCode) {
                                    name
                                    sourceUrl
                                    thumbnails {
                                        ...thumbnail
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        newsFeed(first: $first) {
            edges {
                node {
                    id
                    date
                    featured
                    content(langCode: $langCode) {
                        title
                    }
                    image {
                        ...imagePreview
                    }
                }
            }
        }
        partners {
            id
            content {
                name
            }
            url
            imageUrl
        }
        product(id: "star-walk-2") {
            id
            content(langCode: $langCode) {
                reviews(first: $reviewsFirst) {
                    quote
                    author
                    url
                }
            }
        }
    }
    ${imagePreview}
    ${thumbnail}
`;
