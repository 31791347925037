import React from "react";

interface Props {
    className?: string;
    showFrame?: boolean;
}

const Logo: React.FC<Props> = ({ className, showFrame = false }) => {
    return (
        <span className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox={showFrame ? "0 -25 512 512" : "30 5 482 482"}
                fill="currentColor"
            >
                <circle cx="257.5" cy="283.5" r="19" />
                <circle cx="135" cy="363" r="22.1" />
                <circle cx="54" cy="279" r="22.1" />
                <circle cx="226.5" cy="158.5" r="26" />
                <circle cx="337.5" cy="106.5" r="19" />
                <circle cx="459" cy="133" r="22.1" />
                <rect
                    x="242"
                    y="189"
                    height="16"
                    width="69"
                    rx="8"
                    transform="rotate(77 242 189)"
                />
                <rect
                    x="156"
                    y="340"
                    height="16"
                    width="88"
                    rx="8"
                    transform="rotate(-33.5 156 340)"
                />
                <rect
                    x="79"
                    y="295"
                    height="16"
                    width="60"
                    rx="8"
                    transform="rotate(46 79 295)"
                />
                <rect
                    x="73"
                    y="255"
                    height="16"
                    width="148"
                    rx="8"
                    transform="rotate(-35 73 255)"
                />
                <rect
                    x="251"
                    y="138"
                    height="16"
                    width="66"
                    rx="8"
                    transform="rotate(-25.5 251 138)"
                />
                <rect
                    x="364"
                    y="103"
                    height="16"
                    width="71"
                    rx="8"
                    transform="rotate(12 364 103)"
                />
                {showFrame ? (
                    <rect
                        x="6"
                        y="-19"
                        height="500"
                        width="500"
                        rx="100"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="10"
                    />
                ) : null}
            </svg>
        </span>
    );
};

export default Logo;
