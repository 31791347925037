import React from "react";
import HtmlTitle from "../../helmet/HtmlTitle.js";
import { container } from "../../styles/common.css.js";
import { errorLayout, errorMessage, errorTitle } from "./ErrorPage.css.js";

interface Props {
    title: string;
    message: string;
    children?: React.ReactElement;
}

const ErrorPage: React.FC<Props> = ({ title, message, children }) => {
    return (
        <>
            <HtmlTitle value={title} />
            <div className={container.flex}>
                <div className={errorLayout}>
                    <h1 className={errorTitle}>{title}</h1>
                    <h2 className={errorMessage}>{message}</h2>
                    {children}
                </div>
            </div>
        </>
    );
};

export default ErrorPage;
