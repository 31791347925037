import React from "react";
import { Helmet } from "react-helmet";
import { LangCode } from "../../../_common/types.js";

interface Props {
    domainURL: string;
    path: string;
    langs: LangCode[];
}

const locales: { [lang: string]: string[] } = {
    en: ["en-US", "en-GB", "en-CA"],
    fr: ["fr-FR", "fr-CA"],
    es: ["es-ES", "es-MX", "es-AR", "es-CL"],
    pt: ["pt-BR", "pt-PT"],
};

export const HtmlAlternateLinks: React.FC<Props> = ({
    domainURL,
    path,
    langs,
}) => {
    const alternateLinks: JSX.Element[] = [];
    for (const lang of langs) {
        alternateLinks.push(
            <link
                key={lang}
                rel="alternate"
                hrefLang={lang}
                href={`${domainURL}/${lang}${path}`}
            />,
        );
        if (locales[lang]) {
            for (const locale of locales[lang]) {
                alternateLinks.push(
                    <link
                        key={locale}
                        rel="alternate"
                        hrefLang={locale}
                        href={`${domainURL}/${lang}${path}`}
                    />,
                );
            }
        }
    }
    return (
        <Helmet>
            <link
                rel="alternate"
                hrefLang="x-default"
                href={`${domainURL}/en${path}`}
            />
            {alternateLinks}
        </Helmet>
    );
};

export default HtmlAlternateLinks;
