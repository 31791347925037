import React, { useEffect, useState } from "react";
import { FieldRenderProps } from "react-final-form";
import {
    captchaSendButton,
    chosenIcon,
    errorMessage,
    hiddenWrapper,
    icon,
    iconsBox,
    wrapper,
} from "./Captcha.css.js";
export const icons = [
    {
        name: "apple",
        emoji: "🍏",
    },
    {
        name: "earth",
        emoji: "🌎",
    },
    {
        name: "basketball",
        emoji: "🏀",
    },
    {
        name: "moon",
        emoji: "🌓",
    },
    {
        name: "tree",
        emoji: "🌳",
    },
    {
        name: "chick",
        emoji: "🐥",
    },
    {
        name: "dolphin",
        emoji: "🐬",
    },
    {
        name: "sun",
        emoji: "🌞",
    },
].sort(() => Math.random() - 0.5);
type Props = FieldRenderProps<boolean, any>;
const Captcha: React.FC<Props> = ({ input, meta, buttonText, title }) => {
    const [answers, setAnswers] = useState<string[]>([]);
    const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);
    useEffect(() => {
        setCorrectAnswers(
            answers?.filter(
                item => item === "earth" || item === "sun" || item === "moon",
            ),
        );
    }, [answers]);
    const checkAnswers = () => {
        if (
            correctAnswers?.length === 3 &&
            answers.length === 3 &&
            correctAnswers[0] !== correctAnswers[1]
        ) {
            input.onChange(true);
        } else {
            icons.sort(() => Math.random() - 0.5);
            input.onChange(false);
        }
    };
    return (
        <div className={meta.valid ? hiddenWrapper : wrapper}>
            <div>{title}</div>
            <div className={iconsBox}>
                {icons.map(({ name, emoji }) => (
                    <span
                        className={
                            answers.some(item => item === name)
                                ? chosenIcon
                                : icon
                        }
                        key={name}
                        onClick={() => {
                            setAnswers(answers => [...answers, name]);
                        }}
                    >
                        {emoji}
                    </span>
                ))}
            </div>
            <button
                className={captchaSendButton}
                type="button"
                onClick={() => {
                    checkAnswers();
                    setAnswers([]);
                }}
            >
                {buttonText}
            </button>
            {meta.invalid && meta.modified && (
                <div className={errorMessage}>{meta.error}</div>
            )}
        </div>
    );
};

export default Captcha;
