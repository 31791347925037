import type { PlanetNavigator } from "@vitotechnology/moon-position-ts";
import React, { useContext, useReducer } from "react";
import { SolarObjectsContext } from "./SolarObjectsContextProvider.js";
import { GeoLocationBase } from "../../../graphql/queries/moon-calendar.js";
import { LangCode } from "../../../../_common/types.js";

export interface CityGeoLocation extends GeoLocationBase {
    id: string;
    langs: LangCode[];
    lang: LangCode;
    type: "City";
    name: string;
    country?: string;
    timezone: string;
}

export interface CustomGeoLocation extends GeoLocationBase {
    type: "Custom";
}

export interface CurrentGeoLocation extends GeoLocationBase {
    type: "Current";
}

export type PickedGeoLocation =
    | CityGeoLocation
    | CurrentGeoLocation
    | CustomGeoLocation;

type PickGeoLocationAction = {
    type: "set";
    picked: PickedGeoLocation;
};

type Dispatch = (action: PickGeoLocationAction) => void;

interface GeoLocationContextState {
    pickedGeoLocation: PickedGeoLocation | null;
}

type GeoLocationSetter = (
    prevValue: GeoLocationContextState,
    action: PickGeoLocationAction,
) => GeoLocationContextState;

export const GeoLocationContext = React.createContext<{
    pickedGeoLocation: PickedGeoLocation | null;
    pickGeoLocation: Dispatch;
}>({
    pickGeoLocation: () => {},
    pickedGeoLocation: null,
});

const geoLocationSetterMaker =
    (n: PlanetNavigator): GeoLocationSetter =>
    (_, action) => {
        switch (action.type) {
            case "set": {
                const { latitude, longitude, altitude } = action.picked;
                n.updateGeoLocation({ latitude, longitude, altitude });
                return {
                    pickedGeoLocation: action.picked,
                };
            }
            default:
                throw new Error("Invalid action type in geoLocationSetter");
        }
    };

interface Props {
    children: React.ReactNode;
}

const GeoLocationContextProvider: React.FC<Props> = ({ children }) => {
    const { n } = useContext(SolarObjectsContext);
    const [{ pickedGeoLocation }, pickGeoLocation] = useReducer<
        GeoLocationSetter,
        GeoLocationContextState
    >(
        geoLocationSetterMaker(n),
        {
            pickedGeoLocation: null,
        },
        v => v,
    );

    return (
        <GeoLocationContext.Provider
            value={{ pickedGeoLocation, pickGeoLocation }}
        >
            {children}
        </GeoLocationContext.Provider>
    );
};

export default GeoLocationContextProvider;
