import React from "react";
import { Helmet } from "react-helmet";

interface Props {
    value: string;
}

const HtmlKeywords: React.FC<Props> = ({ value }) => (
    <Helmet>
        <meta name="keywords" content={value} />
    </Helmet>
);

export default HtmlKeywords;
