import React from "react";
import { Helmet } from "react-helmet";
import { BRAND_NAME } from "../../../_common/content.js";

interface Props {
    url: string;
    title: string;
    description: string;
    imageURL: string;
    imageWidth: number;
    imageHeight: number;
    publishedAt?: string;
    updatedAt?: string;
    publisher?: string;
}

const HtmlOpenGraph: React.FC<Props> = ({
    url,
    title,
    description,
    imageURL,
    imageWidth,
    imageHeight,
    publishedAt,
    updatedAt,
    publisher,
}) => {
    return (
        <Helmet>
            <meta
                property="og:type"
                content={publishedAt ? "article" : "website"}
            />
            <meta property="og:site_name" content={BRAND_NAME} />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageURL} />
            <meta property="og:image:secure_url" content={imageURL} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content={`${imageWidth}`} />
            <meta property="og:image:height" content={`${imageHeight}`} />
            <meta property="og:image:alt" content={description} />
            {publisher ? (
                <meta property="article:publisher" content={publisher} />
            ) : null}
            {publishedAt ? (
                <meta property="article:published_time" content={publishedAt} />
            ) : null}
            {updatedAt ? (
                <meta property="article:modified_time" content={updatedAt} />
            ) : null}
        </Helmet>
    );
};

export default HtmlOpenGraph;
