import React, { useContext } from "react";

import { NewsItemForHome } from "../../../graphql/queries/home.js";
import ScrollTracker from "../../common/ScrollTracker.js";
import { container } from "../../styles/common.css.js";
import Content from "../../../i18n/content.js";
import { Link } from "react-router-dom";
import { gaNewsLinkHomeClick } from "../../../helpers/googleAnalytics.js";
import { findClosestThumbnail } from "../../../../_common/utils.js";
import {
    dateInfo,
    imageAspect,
    imageContainer,
    info,
    infoTitle,
    list,
    listItem,
    listItemImage,
    listItemLink,
} from "./NewsSection.css.js";
import {
    commonSection,
    commonSectionText,
    commonSectionTitle,
    sectionContent,
    titlesContainer,
} from "./homeCommon.css.js";
import ArticleInfoBlock from "../../common/ArticleInfoBlock.js";

interface Props {
    news: NewsItemForHome[];
    title: string;
    text: string;
}

const NewsSection: React.FC<Props> = ({ news, text, title }) => {
    const { langCode } = useContext(Content);
    return (
        <section className={commonSection}>
            <div className={container.flex}>
                <div className={sectionContent}>
                    <ScrollTracker
                        position="50%"
                        eventName="news_section_visible"
                    />
                    <div className={titlesContainer}>
                        <h2 className={commonSectionTitle}>{title}</h2>
                        <p className={commonSectionText}>{text}</p>
                    </div>
                    <div className={list}>
                        {news.map(({ id, date, content: { title }, image }) => (
                            <Link
                                key={id}
                                className={listItemLink}
                                to={`/${langCode}/news/${id}`}
                                onClick={() =>
                                    gaNewsLinkHomeClick(id, langCode)
                                }
                            >
                                <div className={listItem}>
                                    <div className={imageContainer}>
                                        <div className={imageAspect}>
                                            <img
                                                className={listItemImage}
                                                src={`${
                                                    image.content.sourceUrl
                                                }${
                                                    findClosestThumbnail(
                                                        image.content
                                                            .thumbnails,
                                                        200,
                                                    ).fileName
                                                }`}
                                                alt={
                                                    image.content.name || title
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className={info}>
                                        <div className={infoTitle}>{title}</div>
                                        <ArticleInfoBlock
                                            className={dateInfo}
                                            date={date}
                                        />
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NewsSection;
