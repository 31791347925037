import ClientCookies from "js-cookie";
import { getLocalStorageItem, setLocalStorageItem } from "../../helpers/utils";

export class CookieConsentService {
    private ACCEPT_COOKIE_NAME = "SWCOOKIESACC";
    private resolveWaitedCookieAccept = () => {};

    public acceptCookie(): void {
        ClientCookies.set(this.ACCEPT_COOKIE_NAME, "1", {
            secure: true,
            sameSite: "strict",
            expires: new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000),
        });
        setLocalStorageItem(this.ACCEPT_COOKIE_NAME, "1");
        if (this.resolveWaitedCookieAccept) {
            this.resolveWaitedCookieAccept();
        }
    }

    public shouldShowCookieConsent(): boolean {
        return (
            ClientCookies.get(this.ACCEPT_COOKIE_NAME) !== "1" &&
            getLocalStorageItem(this.ACCEPT_COOKIE_NAME) !== "1"
        );
    }

    public waitAcceptedCookie(): Promise<void> {
        return new Promise(resolve => {
            if (this.shouldShowCookieConsent()) {
                this.resolveWaitedCookieAccept = resolve;
            } else {
                resolve();
            }
        });
    }
}
