import gql from "graphql-tag";
import { App } from "./apps.js";
import { IImagePreview, Image, IMediaItem } from "./gallery.js";
import {
    Copyright,
    EngagementInfo,
    FeedConnection,
    PromoItem,
    QA,
} from "./_common.js";
import {
    image,
    imagePreview,
    infographicsBannerInfo,
    mediaItem,
    quizBannerInfo,
} from "./fragments.js";
import type { LangCode } from "../../../_common/types.js";
import { Topic } from "./topics.js";

export const newsPreview = gql`
    fragment newsPreview on News {
        id
        date
        content(langCode: $langCode) {
            title
            preview
            readingTime
        }
        image {
            ...image
        }
    }
`;

export interface News<C, I, M = undefined, A = undefined, F = undefined> {
    __typename: "News";
    id: string;
    slug: string | null;
    date: string;
    updatedAt: string;
    featured: boolean;
    contentLangs: LangCode[];
    content: C;
    image: I;
    textImages: (I | null)[] | null;
    mediaItem: M | null;
    promoApps: A[];
    copyright: Copyright;
    featuredNews: F[] | null;
    engagementInfo: EngagementInfo;
    customBanners: NewsCustomBanner[] | null;
    tags: string[] | null;
    promoItems: (PromoItem | null)[] | null;
    topics: (Topic | null)[];
}

export interface NewsCustomBanner {
    id: string;
    imgUrl: string;
    alt: string;
    aspectRatio: string;
    url: string;
    siteAdUnit: string;
}

export interface NewsContent {
    title: string;
    metaTitle: string;
    preview: string;
    text: string;
    readingTime: number | null;
    tocHeader: string | null;
    keywords: string | null;
    faq: QA[] | null;
}

export interface GetNewsVariables {
    newsChannel?: "preview";
    langCode?: LangCode;
    id: string;
    featuredFirst: number;
}

export type NewsItem = News<NewsContent, Image, IMediaItem, App, NewsForFeed>;

export interface GetNewsData {
    news: NewsItem | null;
}

export const GET_NEWS = gql`
    query getNews(
        $newsChannel: NewsChannel
        $langCode: LangCode
        $id: NewsID!
        $featuredFirst: Int
    ) {
        news(newsChannel: $newsChannel, id: $id) {
            id
            slug
            date
            updatedAt
            featured
            contentLangs
            promoItems {
                id
                __typename
                ...quizBannerInfo
                ...infographicsBannerInfo
            }
            content(langCode: $langCode) {
                title
                metaTitle
                preview
                text
                readingTime
                tocHeader
                keywords
                faq {
                    q
                    a
                }
            }
            image {
                ...image
            }
            textImages {
                ...image
            }
            mediaItem {
                ...mediaItem
            }
            promoApps(platforms: [ios, android, huawei]) {
                id
                title
                icon {
                    sourceUrl
                    sizes
                }
                marketUrl
                marketId
                platform
            }
            copyright {
                author
                url
            }
            tags
            customBanners(langCode: $langCode) {
                id
                siteAdUnit
                imgUrl
                alt
                url
                aspectRatio
            }
            featuredNews(newsChannel: $newsChannel, first: $featuredFirst) {
                id
                date
                featured
                content(langCode: $langCode) {
                    title
                    preview
                    readingTime
                }
                image {
                    ...imagePreview
                }
            }
            engagementInfo {
                facebook
                messenger
                twitter
                telegram
                whatsapp
                native
            }
            topics {
                id
                content(langCode: $langCode) {
                    title
                }
            }
        }
    }
    ${imagePreview}
    ${image}
    ${mediaItem}
    ${quizBannerInfo}
    ${infographicsBannerInfo}
`;

export interface GetNewsFeedVariables {
    newsChannel?: "preview";
    langCode: LangCode;
    first: number;
    after?: string;
}

type NewsContentForFeed = Pick<
    NewsContent,
    "title" | "preview" | "readingTime"
>;

export type NewsForFeed = Pick<
    News<NewsContentForFeed, IImagePreview>,
    "__typename" | "id" | "content" | "date" | "featured" | "image"
>;
export interface GetNewsFeedData {
    newsFeed: FeedConnection<NewsForFeed> | null;
}

export const GET_NEWS_FEED = gql`
    query getNewsFeed(
        $newsChannel: NewsChannel
        $langCode: LangCode
        $first: Int
        $after: NewsCursor
    ) {
        newsFeed(newsChannel: $newsChannel, first: $first, after: $after) {
            totalCount
            offset
            edges {
                cursor
                node {
                    id
                    date
                    featured
                    content(langCode: $langCode) {
                        title
                        preview
                        readingTime
                    }
                    image {
                        ...imagePreview
                    }
                }
            }
        }
    }
    ${imagePreview}
`;
