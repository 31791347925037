import React from "react";
import { Field } from "react-final-form";
import { fieldErrorMessage, inputField } from "./formComponents.css.js";
import { formRow } from "../../styles/common.css.js";

interface Props {
    name: string;
    label: string;
    placeholder: string;
    validator: (v: string) => string | undefined;
}

const TextField: React.FC<Props> = ({
    name,
    label,
    placeholder,
    validator,
}) => (
    <Field
        name={name}
        render={({ input, meta, rest }) => (
            <div className={formRow}>
                <label htmlFor={`${name}-input`}>{label}</label>
                <input
                    id={`${name}-input`}
                    className={inputField}
                    type="text"
                    placeholder={placeholder}
                    {...input}
                    {...rest}
                />
                {meta.error && meta.touched && (
                    <span className={fieldErrorMessage}>{meta.error}</span>
                )}
            </div>
        )}
        validate={validator}
    />
);

export default TextField;
