import React, { useContext } from "react";
import { useHistory } from "react-router";
import { commonTitle, container } from "../styles/common.css.js";
import SearchResults from "./SearchResults.js";
import Content from "../../i18n/content.js";
import MetaTags from "../MetaTags.js";
import SearchForm from "./SearchForm.js";

const SearchPage: React.FC = () => {
    const path = "/search";
    const {
        location: { search },
    } = useHistory();
    const {
        langCode,
        langCodes,
        searchPage: { metaTitle, metaDescription, title },
    } = useContext(Content);

    const searchParams = new URLSearchParams(search);
    const q = searchParams.get("q");

    const currentMetaTitle = metaTitle.replace(
        "%{SEARCH_VALUE}",
        `"${q || ""}"`,
    );
    return (
        <>
            <MetaTags
                currentLang={langCode}
                langs={langCodes}
                path={path}
                metaTitle={currentMetaTitle}
                title={currentMetaTitle}
                description={metaDescription}
                noindex
            />
            <div className={container.flex680}>
                <h1 className={commonTitle}>{title}</h1>
                <SearchForm key={q} q={q || ""} />
                <SearchResults />
            </div>
        </>
    );
};

export default SearchPage;
